import Companies from "./Companies.jsx";
import Locations from "./Locations.jsx";
import TeamMembers from "./TeamMembers";
import Profile from "./../examples/Profile.jsx";
import Settings from "./../settings/Settings.jsx";

const routes = [
  {
    path: "/announcements",
    name: "Announcements",
    icon: "fad fa-bullhorn text-organization",
    component: Companies,
    layout: "/team",
  },
  {
    path: "/companies",
    name: "Companies",
    icon: "fad fa-address-book text-organization",
    component: Profile,
    layout: "/team",
  },
  {
    path: "/team-members",
    name: "Team Members",
    icon: "fad fa-users text-organization",
    component: TeamMembers,
    layout: "/team",
  },
  {
    path: "/locations",
    name: "Locations",
    icon: "fad fa-map-marker-alt text-organization",
    component: Locations,
    layout: "/team",
  },
];

export const nonMenuRoutes = [
  {
    collapse: true,
    views: [
      {
        path: "/location/:id",
        name: "Location",
        component: Profile,
        layout: "/app",
      },
      {
        path: "/team-member/:id",
        name: "Team Memeber",
        component: Profile,
        layout: "/app",
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        layout: "/app",
      },
    ],
  },
];

export default routes;
