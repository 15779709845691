import React from "react";
import { Auth, I18n } from "aws-amplify";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
// core
import AdminLayout from "./layouts/App.jsx";
import Admin from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import Organization from "./layouts/Organization.jsx";
import Converse from "./layouts/Converse.jsx";
import Products from "./layouts/Products.jsx";
import Stockpile from "./layouts/Stockpile.jsx";
import Workforce from "./layouts/Workforce.jsx";
import Touchbase from "./layouts/Touchbase.jsx";
import IndexView from "./views/Index.jsx";
import NoAccess from "./views/NoAcces.jsx";

const Layouts = ({ user, site }) => {
  const [_user, setUser] = React.useState([]);
  let { code } = site.currentLanguage();
  I18n.setLanguage(code);
  console.log(code);
  React.useEffect(() => {
    checkForUser();
  });

  const checkForUser = async () => {
    await Auth.currentAuthenticatedUser()
      .then((data) => {
        setUser(user.user);
      })
      .catch((error) => {
        user = null;
      });
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/app" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

        <Route path="/converse" render={(props) => <Converse {...props} />} />
        <Route path="/products" render={(props) => <Products {...props} />} />
        <Route path="/stockpile" render={(props) => <Stockpile {...props} />} />
        <Route path="/team" render={(props) => <Organization {...props} />} />
        {_user && _user.policy && _user.policy.hasTouchbase ? (
          <Route
            path="/touchbase"
            render={(props) => <Touchbase {...props} />}
          />
        ) : (
          <Redirect from="/touchbase/" to="/no-access" />
        )}
        {_user && _user.policy && _user.policy.hasWorkforce && (
          <Route
            path="/workforce"
            render={(props) => <Workforce {...props} />}
          />
        )}
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Route path="/no-access" render={(props) => <NoAccess {...props} />} />
        <Route path="/" render={(props) => <IndexView {...props} />} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};
export default inject("user", "site")(observer(Layouts));
