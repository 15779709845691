/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      firstName
      lastName
      profileImg
      email
      phone
      description
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      location {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      desk_location
      status
      messages {
        id
        users
        group_name
        messages {
          id
          message_group_id
          sender {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          is_sent
          created_at
          text
          geo_location
          media
        }
      }
      policy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isMasterAccount
        isPlatformAdmin
        hasBookkeeper
        hasCabinet
        hasConverse
        hasProjects
        hasStockpile
        hasTechframe
        hasTimetable
        hasTouchbase
        hasWorkforce
        isBookkeeperAdmin
        isCabinetAdmin
        isConverseAdmin
        isGreetAdmin
        isProjectsAdmin
        isStockpileAdmin
        isTimetableAdmin
        isTouchbaseAdmin
        isWorkforceAdmin
      }
      isTalent
      pay
      employmentStatus
      homeAddress {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      startDate
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      firstName
      lastName
      profileImg
      email
      phone
      description
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      location {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      desk_location
      status
      messages {
        id
        users
        group_name
        messages {
          id
          message_group_id
          sender {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          is_sent
          created_at
          text
          geo_location
          media
        }
      }
      policy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isMasterAccount
        isPlatformAdmin
        hasBookkeeper
        hasCabinet
        hasConverse
        hasProjects
        hasStockpile
        hasTechframe
        hasTimetable
        hasTouchbase
        hasWorkforce
        isBookkeeperAdmin
        isCabinetAdmin
        isConverseAdmin
        isGreetAdmin
        isProjectsAdmin
        isStockpileAdmin
        isTimetableAdmin
        isTouchbaseAdmin
        isWorkforceAdmin
      }
      isTalent
      pay
      employmentStatus
      homeAddress {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      startDate
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      firstName
      lastName
      profileImg
      email
      phone
      description
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      location {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      desk_location
      status
      messages {
        id
        users
        group_name
        messages {
          id
          message_group_id
          sender {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          is_sent
          created_at
          text
          geo_location
          media
        }
      }
      policy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isMasterAccount
        isPlatformAdmin
        hasBookkeeper
        hasCabinet
        hasConverse
        hasProjects
        hasStockpile
        hasTechframe
        hasTimetable
        hasTouchbase
        hasWorkforce
        isBookkeeperAdmin
        isCabinetAdmin
        isConverseAdmin
        isGreetAdmin
        isProjectsAdmin
        isStockpileAdmin
        isTimetableAdmin
        isTouchbaseAdmin
        isWorkforceAdmin
      }
      isTalent
      pay
      employmentStatus
      homeAddress {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      startDate
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      stripe
      suspended
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      locations {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      clients {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      teams {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
      stripe
      suspended
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      locations {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      clients {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      teams {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      id
      name
      stripe
      suspended
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      locations {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      clients {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      teams {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
    }
  }
`;
export const addUserToOrganization = /* GraphQL */ `
  mutation AddUserToOrganization($id: ID!, $user: ID!) {
    addUserToOrganization(id: $id, user: $user) {
      id
      name
      stripe
      suspended
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      locations {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      clients {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      teams {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
    }
  }
`;
export const addUserToTeam = /* GraphQL */ `
  mutation AddUserToTeam($id: ID!, $user: ID!) {
    addUserToTeam(id: $id, user: $user) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      description
      team_members {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const addMessageSetToUser = /* GraphQL */ `
  mutation AddMessageSetToUser($id: ID!, $messageSet: ID!) {
    addMessageSetToUser(id: $id, messageSet: $messageSet) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      firstName
      lastName
      profileImg
      email
      phone
      description
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      location {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      desk_location
      status
      messages {
        id
        users
        group_name
        messages {
          id
          message_group_id
          sender {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          is_sent
          created_at
          text
          geo_location
          media
        }
      }
      policy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isMasterAccount
        isPlatformAdmin
        hasBookkeeper
        hasCabinet
        hasConverse
        hasProjects
        hasStockpile
        hasTechframe
        hasTimetable
        hasTouchbase
        hasWorkforce
        isBookkeeperAdmin
        isCabinetAdmin
        isConverseAdmin
        isGreetAdmin
        isProjectsAdmin
        isStockpileAdmin
        isTimetableAdmin
        isTouchbaseAdmin
        isWorkforceAdmin
      }
      isTalent
      pay
      employmentStatus
      homeAddress {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      startDate
    }
  }
`;
export const deleteUserFromOrganization = /* GraphQL */ `
  mutation DeleteUserFromOrganization($id: ID!, $user: ID!) {
    deleteUserFromOrganization(id: $id, user: $user) {
      id
      name
      stripe
      suspended
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      locations {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      clients {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      teams {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
    }
  }
`;
export const deleteUserFromTeam = /* GraphQL */ `
  mutation DeleteUserFromTeam($id: ID!, $user: ID!) {
    deleteUserFromTeam(id: $id, user: $user) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      description
      team_members {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      description
      team_members {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      description
      team_members {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      description
      team_members {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const createMessageGroup = /* GraphQL */ `
  mutation CreateMessageGroup($input: CreateMessageGroupInput!) {
    createMessageGroup(input: $input) {
      id
      users
      group_name
      messages {
        id
        message_group_id
        sender {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        is_sent
        created_at
        text
        geo_location
        media
      }
    }
  }
`;
export const updateMessageGroup = /* GraphQL */ `
  mutation UpdateMessageGroup($input: UpdateMessageGroupInput!) {
    updateMessageGroup(input: $input) {
      id
      users
      group_name
      messages {
        id
        message_group_id
        sender {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        is_sent
        created_at
        text
        geo_location
        media
      }
    }
  }
`;
export const deleteMessageGroup = /* GraphQL */ `
  mutation DeleteMessageGroup($input: DeleteMessageGroupInput!) {
    deleteMessageGroup(input: $input) {
      id
      users
      group_name
      messages {
        id
        message_group_id
        sender {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        is_sent
        created_at
        text
        geo_location
        media
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      message_group_id
      sender {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      is_sent
      created_at
      text
      geo_location
      media
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      message_group_id
      sender {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      is_sent
      created_at
      text
      geo_location
      media
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      message_group_id
      sender {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      is_sent
      created_at
      text
      geo_location
      media
    }
  }
`;
export const createUserConnection = /* GraphQL */ `
  mutation CreateUserConnection($input: CreateUserConnectionInput!) {
    createUserConnection(input: $input) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const updateUserConnection = /* GraphQL */ `
  mutation UpdateUserConnection($input: UpdateUserConnectionInput!) {
    updateUserConnection(input: $input) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const deleteUserConnection = /* GraphQL */ `
  mutation DeleteUserConnection($input: DeleteUserConnectionInput!) {
    deleteUserConnection(input: $input) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      projectName
      description
      dueDate
      startDate
      geoLat
      geoLong
      isFinished
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      purchaseOrder
      sampler
      addressLineOne
      addressLineTwo
      city
      state
      zip
      receipt {
        id
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        amount
        date
        picture
      }
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      projectName
      description
      dueDate
      startDate
      geoLat
      geoLong
      isFinished
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      purchaseOrder
      sampler
      addressLineOne
      addressLineTwo
      city
      state
      zip
      receipt {
        id
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        amount
        date
        picture
      }
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      projectName
      description
      dueDate
      startDate
      geoLat
      geoLong
      isFinished
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      purchaseOrder
      sampler
      addressLineOne
      addressLineTwo
      city
      state
      zip
      receipt {
        id
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        amount
        date
        picture
      }
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      lineOne
      lineTwo
      city
      country
      state
      zip
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      geoLat
      geoLong
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      lineOne
      lineTwo
      city
      country
      state
      zip
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      geoLat
      geoLong
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      lineOne
      lineTwo
      city
      country
      state
      zip
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      geoLat
      geoLong
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember($input: CreateTeamMemberInput!) {
    createTeamMember(input: $input) {
      id
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      id
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember($input: DeleteTeamMemberInput!) {
    deleteTeamMember(input: $input) {
      id
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isOrganization
      clientName
      customerFirstName
      customerLastName
      addressLineOne
      addressLineTwo
      city
      state
      zip
      billingAddressLineOne
      billingAddressLineTwo
      billingCity
      billingState
      billingZip
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isOrganization
      clientName
      customerFirstName
      customerLastName
      addressLineOne
      addressLineTwo
      city
      state
      zip
      billingAddressLineOne
      billingAddressLineTwo
      billingCity
      billingState
      billingZip
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isOrganization
      clientName
      customerFirstName
      customerLastName
      addressLineOne
      addressLineTwo
      city
      state
      zip
      billingAddressLineOne
      billingAddressLineTwo
      billingCity
      billingState
      billingZip
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      creator {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      proj {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      title
      geoLat
      geoLong
      notes
      timeStamp
      media {
        id
        note {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
        mediaURL
      }
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      creator {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      proj {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      title
      geoLat
      geoLong
      notes
      timeStamp
      media {
        id
        note {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
        mediaURL
      }
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      creator {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      proj {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      title
      geoLat
      geoLong
      notes
      timeStamp
      media {
        id
        note {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
        mediaURL
      }
    }
  }
`;
export const createSubdomain = /* GraphQL */ `
  mutation CreateSubdomain($input: CreateSubdomainInput!) {
    createSubdomain(input: $input) {
      subdomain
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const updateSubdomain = /* GraphQL */ `
  mutation UpdateSubdomain($input: UpdateSubdomainInput!) {
    updateSubdomain(input: $input) {
      subdomain
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const deleteSubdomain = /* GraphQL */ `
  mutation DeleteSubdomain($input: DeleteSubdomainInput!) {
    deleteSubdomain(input: $input) {
      subdomain
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      isUserFile
      files {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
        file
        type
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      isUserFile
      files {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
        file
        type
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder($input: DeleteFolderInput!) {
    deleteFolder(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      isUserFile
      files {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
        file
        type
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!) {
    createFile(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
      file
      type
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
      file
      type
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
      file
      type
    }
  }
`;
export const createPolicy = /* GraphQL */ `
  mutation CreatePolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isMasterAccount
      isPlatformAdmin
      hasBookkeeper
      hasCabinet
      hasConverse
      hasProjects
      hasStockpile
      hasTechframe
      hasTimetable
      hasTouchbase
      hasWorkforce
      isBookkeeperAdmin
      isCabinetAdmin
      isConverseAdmin
      isGreetAdmin
      isProjectsAdmin
      isStockpileAdmin
      isTimetableAdmin
      isTouchbaseAdmin
      isWorkforceAdmin
    }
  }
`;
export const updatePolicy = /* GraphQL */ `
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isMasterAccount
      isPlatformAdmin
      hasBookkeeper
      hasCabinet
      hasConverse
      hasProjects
      hasStockpile
      hasTechframe
      hasTimetable
      hasTouchbase
      hasWorkforce
      isBookkeeperAdmin
      isCabinetAdmin
      isConverseAdmin
      isGreetAdmin
      isProjectsAdmin
      isStockpileAdmin
      isTimetableAdmin
      isTouchbaseAdmin
      isWorkforceAdmin
    }
  }
`;
export const deletePolicy = /* GraphQL */ `
  mutation DeletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isMasterAccount
      isPlatformAdmin
      hasBookkeeper
      hasCabinet
      hasConverse
      hasProjects
      hasStockpile
      hasTechframe
      hasTimetable
      hasTouchbase
      hasWorkforce
      isBookkeeperAdmin
      isCabinetAdmin
      isConverseAdmin
      isGreetAdmin
      isProjectsAdmin
      isStockpileAdmin
      isTimetableAdmin
      isTouchbaseAdmin
      isWorkforceAdmin
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
      name
      description
      plainTextDescription
      requirements
      tags
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      name
      description
      plainTextDescription
      requirements
      tags
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      id
      name
      description
      plainTextDescription
      requirements
      tags
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const createTalent = /* GraphQL */ `
  mutation CreateTalent($input: CreateTalentInput!) {
    createTalent(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      application {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      resume
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
    }
  }
`;
export const updateTalent = /* GraphQL */ `
  mutation UpdateTalent($input: UpdateTalentInput!) {
    updateTalent(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      application {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      resume
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
    }
  }
`;
export const deleteTalent = /* GraphQL */ `
  mutation DeleteTalent($input: DeleteTalentInput!) {
    deleteTalent(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      application {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      resume
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      text
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      assignedBy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      onboard {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      project {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      dueDate
      status
      systemTask
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      text
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      assignedBy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      onboard {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      project {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      dueDate
      status
      systemTask
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      text
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      assignedBy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      onboard {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      project {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      dueDate
      status
      systemTask
    }
  }
`;
export const createOnboarding = /* GraphQL */ `
  mutation CreateOnboarding($input: CreateOnboardingInput!) {
    createOnboarding(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      onboardEmailSent
    }
  }
`;
export const updateOnboarding = /* GraphQL */ `
  mutation UpdateOnboarding($input: UpdateOnboardingInput!) {
    updateOnboarding(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      onboardEmailSent
    }
  }
`;
export const deleteOnboarding = /* GraphQL */ `
  mutation DeleteOnboarding($input: DeleteOnboardingInput!) {
    deleteOnboarding(input: $input) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      onboardEmailSent
    }
  }
`;
