/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { inject, observer } from "mobx-react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  UncontrolledDropdown,
  Media,
  Modal,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  CardDeck,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.jsx";
import ReactDatetime from "react-datetime";
// nodejs library that concatenates classes
import classnames from "classnames";

// aws
import { I18n, API, graphqlOperation } from "aws-amplify";
import { strings } from "i18n/workforce/index";
import * as mutations from "../../../../graphql/mutations";
import * as onboardQueries from "../graphql/queries";

import NoProfileImage from "components/Reusable/NoProfileImage";

// time
const moment = require("moment");
I18n.putVocabularies(strings);

let products = [
  {
    name: "Touchbase",
  },
  {
    name: "Bookkeeper",
  },
  {
    name: "Workforce",
  },
  {
    name: "Projects",
  },
  {
    name: "Stockpile",
  },
  {
    name: "Converse",
  },
  {
    name: "Cabinet",
  },
  {
    name: "Greet",
  },
  {
    name: "Timetable",
  },
];

const Onboard = ({ user, history }) => {
  const [newOnboard, setNewOnboard] = React.useState(false);
  const [onboards, setOnboards] = React.useState([]);
  const [organization] = React.useState(user.user.organization.id);
  const [view, setView] = React.useState("showUserSource"); //showUserSource
  const [progress, setProgress] = React.useState(0);
  const [talent, setTalent] = React.useState("setTalent");
  const [teamMember, setTeamMember] = React.useState({ isTalent: false });
  const [roles, setRoles] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [taskEnums, setTaskEnums] = React.useState(null);
  const [location, setLocation] = React.useState({});
  const [taskView, setTaskView] = React.useState("taskSelection");
  const [tasks, setTasks] = React.useState([]);
  const [policy, setPolicy] = React.useState({});
  const [currentTask, setCurrentTask] = React.useState({});
  const [createOnboardProcess, setCreateOnboardProcess] = React.useState("");

  // Form Focusing
  const [focusedFirstName, setFocusedFirstName] = React.useState(false);
  const [focusedMiddleName, setFocusedMiddleName] = React.useState(false);
  const [focusedLastName, setFocusedLastName] = React.useState(false);
  const [email, setFocusedEmail] = React.useState(false);
  const [phone, setFocusedPhone] = React.useState(false);
  const [focusedLineOne, setFocusedLineOne] = React.useState(false);
  const [focusedLineTwo, setFocusedLineTwo] = React.useState(false);
  const [focusedCity, setFocusedCity] = React.useState(false);
  const [focusedState, setFocusedState] = React.useState(false);
  const [focusedPostalCode, setFocusedPostalCode] = React.useState(false);
  const [focusedStartDate, setFocusedStartDate] = React.useState(false);
  const [focusedIsManager, setFocusIsManager] = React.useState(false);

  React.useEffect(() => {
    getOnboardData();
  }, []);

  const getOnboardData = async () => {
    let value;
    try {
      value = await API.graphql(
        graphqlOperation(onboardQueries.OnboardingQuery, {
          organization: organization,
        })
      );
    } catch (error) {
      value = error;
    } finally {
      console.log(value);
      if (value.data) {
        setRoles(value.data.listRolesByOrganization.items);
        setUsers(value.data.listUsersByOrganizationFirstName.items);
        setOnboards(value.data.listOnboardingsByOrganization.items);
        setLocations(value.data.listLocationsByOrganization.items);
        setTalent(value.data.listTalentsByOrganization.items);
        setTaskEnums(value.data.enum.enumValues);
      }
      console.log("ONBOARD QUERY: ", value);
    }
  };

  const createOnboard = async () => {
    let _policy, _location, _onboard, _user;

    //if location create a location and update the team member homeAddress property with id
    if (location) {
      try {
        _location = await API.graphql(
          graphqlOperation(mutations.createLocation, { input: location })
        );
      } catch (error) {
        _location = error;
      } finally {
        if (!_location.data.createLocation) {
          return console.log("Error Creating Policy");
        }
        setTeamMember({
          ...teamMember,
          homeAddress: _location.data.createLocation.id,
        });
      }
    }

    // create a policy and update the team member policy property with id
    try {
      let input = policy;
      input.organization = user.user.organization.id;
      console.log(input);
      _policy = await API.graphql(
        graphqlOperation(mutations.createPolicy, { input: input })
      );
    } catch (error) {
      _policy = error;
    } finally {
      console.log(_policy);
      if (!_policy.data.createPolicy) {
        return console.log("Error Creating Policy");
      }
      setTeamMember({ ...teamMember, policy: _policy.data.createPolicy.id });
      console.log("Policy Created");
    }

    if (teamMember.id) {
      try {
        let input = teamMember;
        input.policy = _policy.data.createPolicy.id;
        if (_location.data.createLocation)
          input.location = _location.data.createLocation.id;
        _user = await API.graphql(
          graphqlOperation(mutations.updateUser, { input: input })
        );
      } catch (error) {
        _user = error;
      } finally {
        console.log(_user);
        if (!_user.data.updateUser) {
          return console.log("Error updating user: ", _user);
        }

        console.log("User Updated");
      }
    } else {
      try {
        let input = teamMember;
        input.organization = user.user.organization.id;
        input.policy = _policy.data.createPolicy.id;
        if (_location.data.createLocation)
          input.location = _location.data.createLocation.id;
        _user = await API.graphql(
          graphqlOperation(mutations.createUser, { input: input })
        );
      } catch (error) {
        _user = error;
      } finally {
        console.log(_user);
        if (!_user.data.createUser) {
          return console.log("Error updating user: ", _user);
        }
      }
      console.log("User Created");
    }

    // create a onbording
    try {
      let input = {
        organization: user.user.organization.id,
        user: _user.data.createUser.id || _user.data.updateUser.id,
      };
      _onboard = await API.graphql(
        graphqlOperation(mutations.createOnboarding, { input: input })
      );
    } catch (error) {
      _onboard = error;
    } finally {
      console.log(_onboard);
      if (!_onboard.data.createOnboarding) {
        return console.log("error creating onboard: ", _onboard);
      }
      console.log("Onboard Created");
    }

    // loop through tasks and create them
    tasks.map((task) => {
      return createTask(
        task,
        _onboard.data.createOnboarding.id,
        _user.data.createUser.id
      );
    });

    getOnboardData();
    setNewOnboard(false);
  };

  const createTask = async (task, onboardingId, userId) => {
    let _task;
    let input = task;
    input.organization = user.user.organization.id;
    input.user = userId;
    input.assignedBy = user.user.id;
    input.onboard = onboardingId;

    try {
      _task = await API.graphql(
        graphqlOperation(mutations.createTask, { input: input })
      );
    } catch (error) {
      _task = error;
    } finally {
      console.log(_task);
      if (!_task.data.createTask) {
        return console.log("Error creating a task: ", _task);
      }
      console.log("Task Created");
    }
  };

  return (
    <>
      <AlternativeHeader
        product={"Workforce"}
        name={I18n.get("Onboarding")}
        onNewPressed={() => {
          setNewOnboard(true);
        }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                      {I18n.get("Talent In Onboard Process")}
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              {onboards.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        {I18n.get("Team Member")}
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        {I18n.get("Manager")}
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        {I18n.get("Role")}
                      </th>
                      <th className="sort" data-sort="completion" scope="col">
                        {I18n.get("Location")}
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {onboards.map((onboard, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              {onboard.user.profileImg ? (
                                <a
                                  className="avatar avatar-sm rounded-circle mr-3"
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt="..."
                                    src={onboard.user.profileImg}
                                  />
                                </a>
                              ) : (
                                <NoProfileImage
                                  id={onboard.user.id}
                                  firstName={onboard.user.firstName}
                                  lastName={onboard.user.lastName}
                                  size="small"
                                />
                              )}

                              <Media>
                                <span className="name mb-0 text-sm">
                                  {onboard.user.firstName}{" "}
                                  {onboard.user.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            {onboard.user.manager && (
                              <>
                                {onboard.user.profileImg ? (
                                  <>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`manager-${index}`}
                                    >
                                      {onboard.user.manager.firstName}{" "}
                                      {onboard.user.manager.lastName}
                                    </UncontrolledTooltip>
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      id={`manager-${index}`}
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img
                                        alt="..."
                                        src={onboard.user.manager.profileImg}
                                      />
                                    </a>
                                  </>
                                ) : (
                                  <NoProfileImage
                                    id={onboard.user.manager.id}
                                    firstName={onboard.user.manager.firstName}
                                    lastName={onboard.user.manager.lastName}
                                    size="small"
                                  />
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              {onboard.user.role.name}
                            </Badge>
                          </td>

                          <td>
                            <a href={`/app/location/id`}>
                              {onboard.user.location.name}
                            </a>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href={`/onboard/${index}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    history.push(`/workforce/onboard/${index}`);
                                  }}
                                >
                                  {I18n.get("Details")}
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                  className="text-warning"
                                >
                                  {I18n.get("Delete")}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>
        </Row>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          keyboard={false}
          isOpen={newOnboard}
          backdrop="static"
          toggle={() => setNewOnboard(!newOnboard)}
        >
          <div className="modal-body p-0" data-testid="onboard-modal">
            <Card className="bg-secondary shadow border-0">
              <div className="modal-header">
                <div className="text-center text-muted ">
                  <h3>{I18n.get("New Onboarding")}</h3>
                </div>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setView("showUserSource");
                    setTeamMember([]);
                    setNewOnboard(!newOnboard);
                  }}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>

              <CardBody className="px-lg-5 py-lg-3">
                {view === "showUserSource" && (
                  <>
                    <div className="text-center text-muted mb-4">
                      <small>
                        {I18n.get(
                          "How would you like to onboard your team member?"
                        )}
                      </small>
                    </div>
                    <CardDeck className="mb-4">
                      <Card
                        onClick={(e) => {
                          if (talent) {
                            setView("selectUserFromTalent");
                          }
                        }}
                        className="card-lift--hover shadow"
                      >
                        <CardBody>
                          <div className="icon icon-shape bg-workforce text-white rounded-circle shadow mb-2">
                            <i className="fad fa-heart mt-1" />
                          </div>
                          <div>
                            <CardTitle className="text-uppercase text-muted mb-0">
                              {I18n.get("Select From Your Talent Pool")}
                            </CardTitle>
                          </div>
                          <p className="mt-2 mb-0 text-sm">
                            {I18n.get(
                              "Easily select a talent profile and transition it into a new team member at your organization."
                            )}
                          </p>
                        </CardBody>
                      </Card>

                      <Card
                        onClick={(e) => {
                          setView("teamMemberOverview");
                          setProgress(progress + 20);
                        }}
                        className="card-lift--hover shadow"
                      >
                        <CardBody>
                          <div className="icon icon-shape bg-workforce text-white rounded-circle shadow mb-2">
                            <i className="fad fa-user mt-1" />
                          </div>
                          <div>
                            <CardTitle className="text-uppercase text-muted mb-0">
                              {I18n.get("Manually Enter Information")}
                            </CardTitle>
                          </div>
                          <p className="mt-2 mb-0 text-sm">
                            {I18n.get(
                              "Don't have a talent profile for this new team member? You can continue by manually adding their information."
                            )}
                          </p>
                        </CardBody>
                      </Card>
                    </CardDeck>
                  </>
                )}

                {view === "selectUserFromTalent" && (
                  <>
                    <div className="text-center text-muted mb-4">
                      <small>
                        {I18n.get(
                          "Enter the name of the talent you would like to onboard"
                        )}
                      </small>
                    </div>

                    <Form role="form">
                      <FormGroup className="mb-1">
                        <FormGroup>
                          <Input
                            id="exampleFormControlSelect1"
                            type="select"
                            onChange={(e) => {
                              let user = JSON.parse(e.target.value).user;
                              setTeamMember(user);
                              setProgress(progress + 20);
                              setView("teamMemberOverview");
                            }}
                          >
                            <option disabled selected>
                              {I18n.get("Select Talent")}
                            </option>
                            {talent.length &&
                              talent
                                .sort((a, b) => {
                                  if (a.user.firstName > b.user.firstName)
                                    return 1;
                                  if (a.user.firstName < b.user.firstName)
                                    return -1;
                                  return 0;
                                })
                                .map((_talent, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={JSON.stringify(_talent)}
                                    >
                                      {_talent.user.firstName}{" "}
                                      {_talent.user.lastName}
                                    </option>
                                  );
                                })}
                          </Input>
                        </FormGroup>
                      </FormGroup>

                      <div className="text-left">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setView("showUserSource");
                            setProgress(progress - 20);
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>
                      </div>
                    </Form>
                  </>
                )}

                {view === "teamMemberOverview" && (
                  <>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setProgress(progress + 20);
                        setView("hr");
                      }}
                    >
                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedFirstName,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-id-card"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                value={
                                  teamMember &&
                                  teamMember.firstName &&
                                  teamMember.firstName
                                }
                                onChange={(e) => {
                                  setTeamMember({
                                    ...teamMember,
                                    firstName: e.target.value,
                                  });
                                }}
                                placeholder={I18n.get("First Name")}
                                type="text"
                                onFocus={() => setFocusedFirstName(true)}
                                onBlur={() => setFocusedFirstName(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedMiddleName,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-id-card"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                value={
                                  teamMember &&
                                  teamMember.middleName &&
                                  teamMember.middleName
                                }
                                onChange={(e) => {
                                  setTeamMember({
                                    ...teamMember,
                                    middleName: e.target.value,
                                  });
                                }}
                                placeholder={I18n.get("Middle Name")}
                                type="text"
                                onFocus={() => setFocusedMiddleName(true)}
                                onBlur={() => setFocusedMiddleName(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedLastName,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-id-card"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                placeholder={I18n.get("Last Name")}
                                type="text"
                                value={
                                  teamMember &&
                                  teamMember.lastName &&
                                  teamMember.lastName
                                }
                                onChange={(e) => {
                                  setTeamMember({
                                    ...teamMember,
                                    lastName: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedLastName(true)}
                                onBlur={() => setFocusedLastName(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: email,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-envelope"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                placeholder={I18n.get("Email")}
                                type="text"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                value={
                                  teamMember &&
                                  teamMember.email &&
                                  teamMember.email
                                }
                                onChange={(e) => {
                                  setTeamMember({
                                    ...teamMember,
                                    email: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedEmail(true)}
                                onBlur={() => setFocusedEmail(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: phone,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-mobile"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                placeholder={I18n.get("Phone")}
                                type="text"
                                value={
                                  teamMember &&
                                  teamMember.phone &&
                                  teamMember.phone
                                }
                                onChange={(e) => {
                                  setTeamMember({
                                    ...teamMember,
                                    phone: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedPhone(true)}
                                onBlur={() => setFocusedPhone(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedLineOne,
                            })}
                          >
                            <label htmlFor="exampleFormControlSelect1">
                              {I18n.get("Home Address")}
                            </label>
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-mailbox"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={I18n.get("Street Line One")}
                                type="text"
                                value={
                                  location &&
                                  location.lineOne &&
                                  teamMember.lineOne
                                }
                                onChange={(e) => {
                                  setLocation({
                                    ...location,
                                    lineOne: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedLineOne(true)}
                                onBlur={() => setFocusedLineOne(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedLineTwo,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-mailbox"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={I18n.get("Street Line Two")}
                                type="text"
                                value={
                                  location &&
                                  location.lineTwo &&
                                  location.lineTwo
                                }
                                onChange={(e) => {
                                  setLocation({
                                    ...location,
                                    lineTwo: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedLineTwo(true)}
                                onBlur={() => setFocusedLineTwo(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedCity,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-map-pin"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={I18n.get("City")}
                                type="text"
                                value={
                                  location && location.city && location.city
                                }
                                onChange={(e) => {
                                  setLocation({
                                    ...location,
                                    city: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedCity(true)}
                                onBlur={() => setFocusedCity(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedState,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-map-pin"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={I18n.get("State")}
                                type="text"
                                value={
                                  location && location.city && location.city
                                }
                                onChange={(e) => {
                                  setLocation({
                                    ...location,
                                    state: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedState(true)}
                                onBlur={() => setFocusedState(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedPostalCode,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-map-pin"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={I18n.get("Postal Code")}
                                type="text"
                                value={location && location.zip && location.zip}
                                onChange={(e) => {
                                  setLocation({
                                    ...location,
                                    zip: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedPostalCode(true)}
                                onBlur={() => setFocusedPostalCode(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="justify-content-between">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setProgress(progress - 20);
                            setView("showUserSource");
                            setTeamMember({});
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>

                        <Button
                          className="my-4 text-right"
                          color="primary"
                          type="submit"
                        >
                          {I18n.get("Continue")}
                        </Button>
                      </Row>
                    </Form>
                  </>
                )}

                {view === "hr" && (
                  <>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setProgress(progress + 20);
                        setView("tasks");
                      }}
                    >
                      <Row>
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: focusedStartDate,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fad fa-calendar" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: I18n.get("Start Date"),
                                  required: true,
                                }}
                                timeFormat={false}
                                onChange={(e) =>
                                  setTeamMember({
                                    ...teamMember,
                                    startDate: e.toString(),
                                  })
                                }
                                onFocus={() => setFocusedStartDate(true)}
                                onBlur={() => setFocusedStartDate(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: phone,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-hand-holding-usd"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                pattern="^\d*(\.\d{0,2})?$"
                                placeholder={I18n.get("Pay")}
                                type="text"
                                value={teamMember.pay && teamMember.pay}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setTeamMember({
                                    ...teamMember,
                                    pay: parseFloat(value),
                                  });
                                }}
                                onFocus={() => setFocusedPhone(true)}
                                onBlur={() => setFocusedPhone(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          id="fullTime"
                          name="payType"
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="fullTime"
                        >
                          {I18n.get("Full-time")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          id="partTime"
                          name="payType"
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="partTime"
                        >
                          {I18n.get("Part-time")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          id="temporary"
                          name="payType"
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="temporary"
                        >
                          {I18n.get("Temporary")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          id="seasonal"
                          name="payType"
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="seasonal"
                        >
                          {I18n.get("Seasonal")}
                        </label>
                      </div>

                      <Row className="mt-4">
                        <Col>
                          <FormGroup>
                            <Input
                              id="exampleFormControlSelect1"
                              type="select"
                              onChange={(e) => {
                                setTeamMember({
                                  ...teamMember,
                                  role: e.target.value,
                                });
                              }}
                            >
                              <option disabled selected>
                                {I18n.get("Select Role")}
                              </option>
                              {roles &&
                                roles.map((role, index) => {
                                  return (
                                    <option value={role.id}>{role.name}</option>
                                  );
                                })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              id="exampleFormControlSelect2"
                              type="select"
                              onChange={(e) => {
                                setTeamMember({
                                  ...teamMember,
                                  manager: e.target.value,
                                });
                              }}
                            >
                              <option disabled selected>
                                {I18n.get("Select Manager")}
                              </option>
                              {users &&
                                users.map((user, index) => {
                                  if (user.isTalent) {
                                    return null;
                                  }
                                  return (
                                    <option value={user.id}>
                                      {user.firstName} {user.lastName}{" "}
                                    </option>
                                  );
                                })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              id="exampleFormControlSelect3"
                              type="select"
                              onChange={(e) => {
                                setTeamMember({
                                  ...teamMember,
                                  location: e.target.value,
                                });
                              }}
                            >
                              <option disabled selected>
                                {I18n.get("Select Location")}
                              </option>
                              {locations &&
                                locations.map((location, index) => {
                                  return (
                                    <option value={location.id}>
                                      {location.name}
                                    </option>
                                  );
                                })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="justify-content-between">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setProgress(progress - 20);
                            setView("teamMemberOverview");
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>

                        <Button
                          className="my-4 text-right"
                          color="primary"
                          type="submit"
                        >
                          {I18n.get("Continue")}
                        </Button>
                      </Row>
                    </Form>
                  </>
                )}

                {view === "tasks" && (
                  <>
                    <div className="text-center text-muted mb-4">
                      <small>
                        {I18n.get(
                          "Assign tasks for your new team member to complete"
                        )}
                      </small>
                    </div>
                    {taskView === "taskSelection" && (
                      <CardDeck className="mb-4">
                        <Card
                          onClick={(e) => {
                            if (talent) {
                              setTaskView("customTask");
                            }
                          }}
                          className="card-lift--hover shadow"
                        >
                          <CardBody>
                            <div className="icon icon-shape bg-workforce text-white rounded-circle shadow mb-2">
                              <i className="fad fa-pen mt-1" />
                            </div>
                            <div>
                              <CardTitle className="text-uppercase text-muted mb-0">
                                {I18n.get("Custom Task")}
                              </CardTitle>
                            </div>
                            <p className="mt-2 mb-0 text-sm">
                              {I18n.get(
                                "Assign your new team member a custom task (e.g. Meet with your manager)"
                              )}
                            </p>
                          </CardBody>
                        </Card>

                        <Card
                          onClick={(e) => {
                            setTaskView("systemTask");
                          }}
                          className="card-lift--hover shadow"
                        >
                          <CardBody>
                            <div className="icon icon-shape bg-workforce text-white rounded-circle shadow mb-2">
                              <i className="fad fa-microchip mt-1" />
                            </div>
                            <div>
                              <CardTitle className="text-uppercase text-muted mb-0">
                                {I18n.get("Use System Defined Task")}
                              </CardTitle>
                            </div>
                            <p className="mt-2 mb-0 text-sm">
                              {I18n.get(
                                "A system defined task will make your team member preform an action within the asOne platform"
                              )}
                            </p>
                          </CardBody>
                        </Card>
                      </CardDeck>
                    )}

                    {taskView === "customTask" && (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setTasks(tasks.concat(currentTask));
                          setCurrentTask({});
                          setTaskView("taskSelection");
                          console.log(tasks);
                        }}
                      >
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: phone,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-task"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                placeholder={I18n.get("Task")}
                                type="text"
                                value={currentTask.task && currentTask.task}
                                onChange={(e) => {
                                  setCurrentTask({
                                    ...currentTask,
                                    text: e.target.value,
                                  });
                                }}
                                onFocus={() => setFocusedPhone(true)}
                                onBlur={() => setFocusedPhone(false)}
                              />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fad fa-calendar" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: I18n.get("Select Due Date"),
                                  required: true,
                                }}
                                timeFormat={false}
                                onChange={(e) =>
                                  setCurrentTask({
                                    ...currentTask,
                                    dueDate: e.toString(),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Row className="justify-content-between">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setTaskView("taskSelection");
                            }}
                          >
                            {I18n.get("Cancel")}
                          </Button>

                          <Button
                            className="my-4 text-right"
                            color="primary"
                            type="submit"
                            onClick={() => {
                              setCurrentTask({
                                ...currentTask,
                                status: taskEnums[0].name,
                                systemTask: null,
                              });
                            }}
                          >
                            {I18n.get("Assign Task")}
                          </Button>
                        </Row>
                      </Form>
                    )}

                    {taskView === "systemTask" && (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setTasks(tasks.concat(currentTask));
                          setCurrentTask({});
                          setTaskView("taskSelection");
                          console.log(tasks);
                        }}
                      >
                        <Col>
                          <FormGroup
                            className={classnames({
                              focused: phone,
                            })}
                          >
                            <Input
                              required
                              type="select"
                              value={currentTask.task && currentTask.task}
                              onChange={(e) => {
                                setCurrentTask({
                                  ...currentTask,
                                  ...JSON.parse(e.target.value),
                                });
                              }}
                              onFocus={() => setFocusedPhone(true)}
                              onBlur={() => setFocusedPhone(false)}
                            >
                              <option disabled selected>
                                {I18n.get("Select System Task")}
                              </option>
                              <option
                                value={JSON.stringify({
                                  text: "Add Bank Information",
                                  status: taskEnums[0].name,
                                  systemTask: true,
                                })}
                              >
                                {I18n.get("Add Bank Account Information")}
                              </option>
                              <option
                                value={JSON.stringify({
                                  text: "Select Benefits",
                                  status: taskEnums[0].name,
                                  systemTask: true,
                                })}
                              >
                                {I18n.get("Select Benefits")}
                              </option>
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fad fa-calendar" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: I18n.get("Select Due Date"),
                                  required: true,
                                }}
                                timeFormat={false}
                                onChange={(e) =>
                                  setCurrentTask({
                                    ...currentTask,
                                    dueDate: e.toString(),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Row className="justify-content-between">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setTaskView("taskSelection");
                            }}
                          >
                            {I18n.get("Cancel")}
                          </Button>

                          <Button
                            className="my-4 text-right"
                            color="primary"
                            type="submit"
                            onClick={() => {
                              setCurrentTask({
                                ...currentTask,
                                status: taskEnums[0].name,
                              });
                            }}
                          >
                            {I18n.get("Assign Task")}
                          </Button>
                        </Row>
                      </Form>
                    )}

                    {tasks.length > 0 && taskView === "taskSelection" && (
                      <Table className="align-items-center" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">{I18n.get("Task")}</th>
                            <th scope="col">{I18n.get("Due Date")}</th>
                            <th scope="col">{I18n.get("System Task")}</th>
                            <th scope="col">{I18n.get("Status")}</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasks &&
                            tasks.map((task, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{task.text && task.text}</td>
                                  <td>
                                    {task.dueDate &&
                                      moment(task.dueDate).format("MM DD YYYY")}
                                  </td>
                                  <td>
                                    {task.systemTask && task.systemTask
                                      ? I18n.get("Yes")
                                      : I18n.get("No")}
                                  </td>
                                  <td>{task.status && task.status} </td>
                                  <td
                                    onClick={() => {
                                      setTasks((tasks) =>
                                        tasks.filter((task, i) => i !== index)
                                      );
                                    }}
                                  >
                                    <i className="fad fa-trash" />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    )}

                    {taskView === "taskSelection" && (
                      <Row className="mt-4 justify-content-between">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            setProgress(progress - 20);
                            e.preventDefault();
                            setView("hr");
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>

                        <Button
                          className="my-4 text-right"
                          color="primary"
                          type="button"
                          onClick={(e) => setView("thirdParty")}
                        >
                          {I18n.get("Continue")}
                        </Button>
                      </Row>
                    )}
                  </>
                )}

                {view === "thirdParty" && (
                  <>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setProgress(progress + 20);
                        setView("products");
                      }}
                    >
                      <Row className="mt-4 justify-content-between">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setProgress(progress - 20);
                            setView("hr");
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>

                        <Button
                          className="my-4 text-right"
                          color="primary"
                          type="submit"
                        >
                          {I18n.get("Continue")}
                        </Button>
                      </Row>
                    </Form>
                  </>
                )}

                {view === "products" && (
                  <>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setProgress(progress + 20);
                        setView("finalReview");
                        console.log(teamMember);
                      }}
                    >
                      {products.map((product, index) => {
                        return (
                          <>
                            <Row key={index}>
                              <Col>
                                <h4>{product.name}</h4>
                              </Col>
                              <Col className="text-right">
                                <label className="custom-toggle">
                                  <input
                                    onChange={(e) => {
                                      let policyKey = `has${product.name}`;
                                      setPolicy({
                                        ...policy,
                                        [policyKey]: e.target.checked,
                                      });
                                    }}
                                    type="checkbox"
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                              </Col>
                            </Row>
                            {policy[`has${product.name}`] && (
                              <Row>
                                <Col>
                                  <p>
                                    {I18n.get("Set user as product admin?")}
                                  </p>
                                </Col>

                                <Col className="text-right">
                                  <label className="custom-toggle">
                                    <input
                                      onChange={(e) => {
                                        let policyKey = `is${product.name}Admin`;
                                        setPolicy({
                                          ...policy,
                                          [policyKey]: e.target.checked,
                                        });
                                        console.log(policy);
                                      }}
                                      type="checkbox"
                                    />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </Col>
                              </Row>
                            )}
                          </>
                        );
                      })}

                      <hr />
                      <Row>
                        <Col>
                          <h4>{I18n.get("Platform Admin")}</h4>
                        </Col>
                        <Col className="text-right">
                          <label className="custom-toggle">
                            <input
                              onChange={(e) => {
                                let policyKey = `isPlatformAdmin`;
                                setPolicy({
                                  ...policy,
                                  [policyKey]: e.target.checked,
                                });
                              }}
                              type="checkbox"
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </Col>
                      </Row>

                      <Row className="mt-4 justify-content-between">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setProgress(progress - 20);
                            setView("thirdParty");
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>

                        <Button
                          className="my-4 text-right"
                          color="primary"
                          type="submit"
                        >
                          {I18n.get("Continue")}
                        </Button>
                      </Row>
                    </Form>
                  </>
                )}

                {view === "finalReview" && (
                  <>
                    <Form>
                      <Row>
                        <Col>
                          <h3 className="text-muted">
                            {I18n.get("Team Member Information")}
                          </h3>
                          <p>
                            {teamMember.firstName}{" "}
                            {teamMember.middleName && teamMember.middleName}{" "}
                            {teamMember.lastName}{" "}
                          </p>
                          <p>{teamMember.email} </p>
                          <p>{teamMember.phone} </p>
                        </Col>

                        <Col className={"text-right"}>
                          {teamMember.location && (
                            <>
                              <h3 className="text-muted">
                                {I18n.get("Team Member's Location")}
                              </h3>
                              {locations
                                .filter(
                                  (location) =>
                                    location.id === teamMember.location
                                )
                                .map((location, index) => {
                                  return <p key={index}>{location.name}</p>;
                                })}
                            </>
                          )}

                          {teamMember.manager && (
                            <>
                              <h3 className="text-muted">
                                {I18n.get("Team Member's Manager")}
                              </h3>
                              {users
                                .filter(
                                  (user) => user.id === teamMember.manager
                                )
                                .map((user, index) => {
                                  return (
                                    <p key={index}>
                                      {user.firstName} {user.lastName}
                                    </p>
                                  );
                                })}
                            </>
                          )}

                          {teamMember.role && (
                            <>
                              <h3 className="text-muted">
                                {I18n.get("Team Member's Role")}
                              </h3>
                              {roles
                                .filter((role) => role.id === teamMember.role)
                                .map((role, index) => {
                                  return <p key={index}>{role.name}</p>;
                                })}
                            </>
                          )}
                        </Col>
                      </Row>
                      {tasks.length > 0 && (
                        <Row>
                          <h3 className="text-muted">
                            {I18n.get("Team Member's Tasks")}
                          </h3>
                          <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">{I18n.get("Task")}</th>
                                <th scope="col">{I18n.get("Due Date")}</th>
                                <th scope="col">{I18n.get("System Task")}</th>
                                <th scope="col">{I18n.get("Status")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tasks &&
                                tasks.map((task, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{task.text && task.text}</td>
                                      <td>
                                        {task.dueDate &&
                                          moment(task.dueDate).format(
                                            "MM DD YYYY"
                                          )}
                                      </td>
                                      <td>
                                        {task.systemTask && task.systemTask
                                          ? I18n.get("Yes")
                                          : I18n.get("No")}
                                      </td>
                                      <td>{task.status && task.status} </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Row>
                      )}
                      <Row className="mt-4 justify-content-between">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            setProgress(progress - 20);
                            setView("products");
                          }}
                        >
                          {I18n.get("Go back")}
                        </Button>

                        <Button
                          className="my-4 text-right"
                          color="primary"
                          type="button"
                          onClick={(e) => {
                            createOnboard();
                          }}
                        >
                          {I18n.get("Onboard")}
                        </Button>
                      </Row>
                    </Form>
                  </>
                )}
              </CardBody>
              <CardFooter className="bg-transparent">
                {view === "showUserSource" ? (
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col>
                      {I18n.get(
                        "Need to onboard multiple users? Download the onboard file and upload multiple users"
                      )}
                    </Col>
                    <Col className="align-self-end">
                      <Button>{I18n.get("Select File")}</Button>
                    </Col>
                  </Row>
                ) : (
                  <div className="text-muted text-center">
                    <div className="progress-wrapper">
                      <div className="progress-info">
                        <div className="progress-label">
                          <span>{I18n.get("Progress")}</span>
                        </div>
                        <div className="progress-percentage">
                          <span>{progress}%</span>
                        </div>
                      </div>
                      <Progress max="100" value={progress} />
                    </div>
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default inject("user", "site")(observer(Onboard));
