/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link as LinkScroll } from "react-scroll";

class IndexHeader extends React.Component {
  render() {
    let { product } = this.props;
    return (
      <>
        <div className="header bg-info pt-5 pb-7">
          <Container>
            <div className="header-body">
              <Row className="align-items-center pt-5">
                <Col lg="6">
                  <div className="pr-5">
                    <h1 className="display-2 text-white font-weight-bold mb-0">
                      {product.data.name[0].text}
                    </h1>
                    <h2 className="display-4 text-white font-weight-light">
                      {product.data.slogan[0].text}
                    </h2>
                    <p className="text-white mt-4">
                      {product.data.soustext[0].text}
                    </p>
                    <div className="mt-5">
                      <LinkScroll className="mr-2" to="features" smooth={true}>
                        <Button
                          className="btn-neutral"
                          color="default"
                          tag={Link}
                        >
                          Explore {product.data.name[0].text} Features
                        </Button>
                      </LinkScroll>
                      <Button
                        className="my-2"
                        color="default"
                        href="/auth/register"
                      >
                        Sign Up Now
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col className="pt-5" lg="6" style={{ overflow: "hidden" }}>
                  <img
                    width="500"
                    src={product.data.header_image.url}
                    alt={`as one ${product.data.name[0].text}`}
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}

export default IndexHeader;
