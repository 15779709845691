/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";

// AWS
import API, { graphqlOperation } from "@aws-amplify/api";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import InputRange from "react-input-range";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import IndexHeader from "components/Headers/IndexHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

import Icon from "../../../components/Icon/asone.jsx";
import "react-input-range/lib/css/index.css";

var Prismic = require("prismic-javascript");
var apiEndpoint = "https://asone.cdn.prismic.io/api/v2";

class Index extends React.Component {
  state = {
    value: 0,
    pricing: [],
    products: null,
    totalMonthlyPrice: null,
  };

  async componentDidMount() {
    let products = null;
    await Prismic.getApi(apiEndpoint)
      .then(function (api) {
        return api.query(Prismic.Predicates.at("document.type", "product"));
      })
      .then(
        function (response) {
          products = response.results;
        },
        function (err) {
          console.log("Something went wrong: ", err);
        }
      );

    this.setState({ products });
  }

  async calculatePricing(value, uid, pricing) {
    this.setState({ [uid]: value });
    let productTotal = 0;
    console.log("Selected Value: ", value);

    let amountsToCharge = await pricing.map((price, index) => {
      let priceObject = price;
      if (value > parseInt(price.max)) {
        if (index === 0) {
          value = value - parseInt(price.max);
          console.log("1 Value: ", value);
          priceObject.amountToCharge = parseInt(price.max);
        } else if (index === pricing.length - 1) {
          console.log("Final Value: ", value);
          priceObject.amountToCharge = value;
        } else {
          value = value - (parseInt(price.max) - parseInt(price.min));
          priceObject.amountToCharge =
            parseInt(price.max) - parseInt(price.min);
          console.log(index + 1 + " Value: ", value);
        }
      } else {
        console.log("Value Amount < price.max : ", value);
        priceObject.amountToCharge = value;
        value = 0;
      }
      return priceObject;
    });
    console.log(amountsToCharge);

    await amountsToCharge.map((pricing) => {
      if (pricing.amountToCharge) {
        let total = pricing.amountToCharge * parseFloat(pricing.amount);
        console.log("Quantity: ", pricing.amountToCharge);
        console.log("Amount: ", parseFloat(pricing.amount));
        console.log("Step Total", total);
        productTotal = productTotal + total;
      }
    });
    console.log(pricing);
    let amounts = this.state.pricing;
    amounts[uid] = productTotal.toFixed(2);
    console.log("$", productTotal.toFixed(2));
    this.setState({ pricing: amounts });
    this.getTotalMonthlyPrice();
  }

  getTotalMonthlyPrice() {
    let { products, pricing } = this.state;
    let newTotal = 0;
    products.map((product) => {
      if (pricing[product.uid]) {
        let amount = pricing[product.uid];
        newTotal = newTotal + parseFloat(amount);
      }
    });
    this.setState({ totalMonthlyPrice: newTotal.toFixed(2) });
  }

  render() {
    let { products, totalMonthlyPrice } = this.state;
    return (
      <>
        <IndexNavbar />
        <div className="main-content">
          <IndexHeader />
          <section id="products" className="py-6 pb-9 bg-default">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="6">
                  <h2 className="display-3 text-white">
                    A Complete Cloud Based Work Solution
                  </h2>
                  <p className="lead text-white">
                    asOne is cloud based work products like never seen before.
                    It was made for those who want to get things done and not be
                    held up by their technology. asOne can help a new
                    organization get started and optimize multi-million dollar
                    organizations. All of our products work together to help you
                    reduce workload and improve employee and client
                    satisfaction.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <seection>
            <Container className="mt--6" fluid>
              <Row className="justify-content-center">
                <Col sm={12} md={8}>
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col>
                          <h6 className="surtitle">
                            Determine How Much You Will Pay
                          </h6>
                          <h5 className="h2 mb-0">Pricing Calulator</h5>
                        </Col>
                        <Col>
                          <h6 className="surtitle text-right">
                            total per month
                          </h6>
                          <h5 className="h2 mb-0 text-right">
                            $
                            {totalMonthlyPrice
                              ? totalMonthlyPrice
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : "0.00"}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        {products &&
                          products
                            .sort(function (a, b) {
                              if (a.uid < b.uid) {
                                return -1;
                              }
                              if (a.uid > b.uid) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((product, index) => {
                              return (
                                <Col xs={12} className="mb-5">
                                  <Row>
                                    <Col xs="1">
                                      <div
                                        className="icon icon-shape text-white rounded-circle mt-2 mb-2"
                                        style={{
                                          background: `linear-gradient(87deg, ${product.data.color_secondary} 0, ${product.data.color_primary} 100%)`,
                                        }}
                                      >
                                        <i
                                          className={product.data.nucleo_icon}
                                        />
                                      </div>
                                    </Col>
                                    <Col>
                                      <h4 className="surtitle">
                                        {product && product.uid}
                                      </h4>
                                      <p className="text-muted">
                                        {product && product.data.slogan[0].text}{" "}
                                      </p>
                                    </Col>
                                    <Col>
                                      <h5 className="h2 text-muted mb-0 text-right">
                                        $
                                        {this.state.pricing &&
                                        this.state.pricing[product.uid]
                                          ? this.state.pricing[product.uid]
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          : 0.0}
                                      </h5>
                                    </Col>
                                  </Row>

                                  <input
                                    style={{ width: "100%" }}
                                    id="typeinp"
                                    type="range"
                                    min="1"
                                    max="500"
                                    value={this.state[product.uid] || 0}
                                    onChange={(e) =>
                                      this.calculatePricing(
                                        e.target.value,
                                        product.uid,
                                        product.data.pricing
                                      )
                                    }
                                    step="1"
                                  />
                                  <p className="text-muted text-center">{`${
                                    this.state[product.uid] || 0
                                  } users`}</p>
                                </Col>
                              );
                            })}
                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </Container>
          </seection>
        </div>
      </>
    );
  }
}

export default Index;
