import Products from "./Products.jsx";
import Product from "./Product.jsx";
import PricingCalculator from "./PricingCalculator.jsx";

export const nonMenuRoutes = [
  {
    path: "/",
    name: "Products",
    component: Products,
    layout: "/products",
  },
  {
    path: "/pricing-calculator",
    name: "Product",
    component: PricingCalculator,
    layout: "/products",
  },
  {
    path: "/:uuid",
    name: "Product",
    component: Product,
    layout: "/products",
  },
];
