import React from "react";
import classnames from "classnames";
// AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
//mobx
import { observer, inject } from "mobx-react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import AlternativeHeader from "../../../../components/Headers/AlternativeHeader";
import TeamMemberCard from "components/Reusable/TeamMemberCard";
const moment = require("moment");

const TeamMember = ({ user, match, history }) => {
  const [teamMember, setTeamMember] = React.useState(null);
  const [navPill, setNavPill] = React.useState(1);

  React.useEffect(() => {
    let { id } = user.user.organization;
    getUser(id);
  }, []);

  const getUser = async (organization) => {
    let { id } = match.params;
    let _user;
    try {
      _user = await API.graphql(graphqlOperation(queries.getUser, { id: id }));
    } catch (error) {
      _user = error;
    } finally {
      let userInfo;
      if (_user.data.getUser) {
        userInfo = _user.data.getUser;
      } else {
        console.log(_user);
        return history.push("/404");
      }
      if (userInfo.organization.id === organization) {
        setTeamMember(userInfo);
      } else {
        console.log(
          `Org mismatch: Team Member: ${userInfo.organization.id} | User: ${organization}`
        );
      }
    }
  };
  if (!teamMember) {
    return null;
  }
  return (
    <>
      <AlternativeHeader
        item={`${teamMember.firstName} ${teamMember.lastName}`}
        name={"Team Member"}
        product={"Workforce"}
      />
      <Container className="mt--6" fluid>
        <Nav
          className="nav-fill flex-column flex-sm-row mb-2"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={navPill === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: navPill === 1,
              })}
              onClick={() => setNavPill(1)}
              role="tab"
            >
              Overview
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={navPill === 2}
              className={classnames("mb-sm-3 mb-md-0", {
                active: navPill === 2,
              })}
              onClick={() => setNavPill(2)}
              role="tab"
            >
              Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={navPill === 3}
              className={classnames("mb-sm-3 mb-md-0", {
                active: navPill === 3,
              })}
              onClick={() => setNavPill(3)}
              role="tab"
            >
              Reviews
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={navPill === 4}
              className={classnames("mb-sm-3 mb-md-0", {
                active: navPill === 4,
              })}
              onClick={() => setNavPill(4)}
              role="tab"
            >
              Complaints
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={navPill === 5}
              className={classnames("mb-sm-3 mb-md-0", {
                active: navPill === 5,
              })}
              onClick={() => setNavPill(5)}
              role="tab"
            >
              More
            </NavLink>
          </NavItem>
        </Nav>

        <Row>
          <Col className="order-xl-2" xl="4">
            <Card>
              <CardBody>
                {teamMember.profileImaage ? (
                  <a
                    href={`/workforce/team-member/${teamMember.id}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={"https://randomuser.me/api/portraits/women/23.jpg"}
                      width="128"
                      height="128"
                    />
                  </a>
                ) : (
                  <a
                    href={`/workforce/team-member/${teamMember.id}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    <div
                      style={{ height: 128, width: 128 }}
                      className="rounded-circle img-center bg-primary justify-content-baseline align-items-center"
                    >
                      <h1
                        className="text-center text-white"
                        style={{ fontSize: 64, lineHeight: 1.8 }}
                      >
                        {teamMember.firstName[0].toUpperCase()}
                        {teamMember.lastName[0].toUpperCase()}
                      </h1>
                    </div>
                  </a>
                )}

                <div className="pt-4 text-center">
                  <h5 className="h3 title">
                    <span className="d-block mb-1">{`${teamMember.firstName} ${teamMember.lastName}`}</span>
                    <small className="h4 font-weight-light text-muted">
                      <a href={`/workforce/role/${teamMember.role.id}`}>
                        {teamMember.role.name}
                      </a>
                    </small>
                  </h5>
                  <div className="mt-3">
                    <Button
                      className="btn-icon-only rounded-circle"
                      color="twitter"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button
                      className="btn-icon-only rounded-circle"
                      color="facebook"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button
                      className="btn-icon-only rounded-circle"
                      color="dribbble"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
            {teamMember.manager && (
              <>
                <h3 className="mb-3">Manager</h3>
                <TeamMemberCard
                  teamMember={teamMember.manager}
                  to={`/workforce/team-member/${teamMember.manager.id}`}
                  showAdd
                  col={12}
                />
              </>
            )}
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Complaint Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Title</h6>
                  <p>
                    One of the managers always tells me to put my hair up
                    because he likes it that way. It's gross. I wanted you to
                    know about it
                  </p>

                  <h6 className="heading-small text-muted mb-4">Date</h6>
                  <p>
                    {moment("2020-05-13T19:09:33-04:00").format("MM/DD/YYYY")}
                  </p>

                  <h6 className="heading-small text-muted mb-4">Description</h6>
                  <div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Faucibus et molestie ac feugiat sed lectus
                      vestibulum mattis. Purus viverra accumsan in nisl nisi
                      scelerisque eu. Sed libero enim sed faucibus turpis in.
                      Rutrum quisque non tellus orci ac. Id interdum velit
                      laoreet id donec ultrices tincidunt arcu. Ultricies leo
                      integer malesuada nunc vel risus commodo. Odio euismod
                      lacinia at quis risus sed vulputate. Etiam sit amet nisl
                      purus in mollis nunc. Viverra ipsum nunc aliquet bibendum.
                      Dictum fusce ut placerat orci nulla pellentesque
                      dignissim. Arcu odio ut sem nulla pharetra diam sit amet
                      nisl. Diam maecenas sed enim ut sem viverra aliquet. Lacus
                      luctus accumsan tortor posuere ac ut consequat. Sed
                      viverra ipsum nunc aliquet bibendum enim facilisis gravida
                      neque. Dolor sed viverra ipsum nunc aliquet bibendum enim
                      facilisis gravida. Tortor id aliquet lectus proin nibh
                      nisl condimentum. Egestas purus viverra accumsan in nisl
                      nisi. Varius sit amet mattis vulputate enim nulla aliquet.
                      Venenatis a condimentum vitae sapien. In fermentum posuere
                      urna nec tincidunt praesent. Accumsan tortor posuere ac ut
                      consequat semper. Viverra adipiscing at in tellus integer
                      feugiat.
                    </p>
                    <p>
                      Orci porta non pulvinar neque. Lectus vestibulum mattis
                      ullamcorper velit. Mauris augue neque gravida in. Nullam
                      eget felis eget nunc lobortis. Nunc sed blandit libero
                      volutpat sed cras ornare arcu. Et egestas quis ipsum
                      suspendisse ultrices gravida dictum. Eleifend donec
                      pretium vulputate sapien nec sagittis aliquam malesuada.
                      Aliquam etiam erat velit scelerisque in dictum non. A arcu
                      cursus vitae congue mauris rhoncus aenean. Vulputate
                      dignissim suspendisse in est ante in nibh. Id donec
                      ultrices tincidunt arcu non sodales neque sodales ut.
                      Tristique senectus et netus et malesuada fames. Massa sed
                      elementum tempus egestas sed sed risus pretium. Neque
                      sodales ut etiam sit amet nisl purus. Diam maecenas
                      ultricies mi eget. Pharetra magna ac placerat vestibulum.
                      Aliquam id diam maecenas ultricies mi eget mauris
                      pharetra. Pretium nibh ipsum consequat nisl.
                    </p>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default inject("user")(observer(TeamMember));
