/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.maisondecode.com/product/argon-dashboard-pro-react
* Copyright 2019 Maison de Code (https://www.maisondecode.com)

* Coded by Maison de Code

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { inject, observer } from "mobx-react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import languages from "../../languages.json";

class AuthFooter extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://www.maisondecode.com?ref=adpr-admin-footer"
                    target="_blank"
                  >
                    Maison de Code
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer align-items-baseline justify-content-center justify-content-lg-end">
                  <NavItem className="d-block">
                    <UncontrolledDropdown direction={"up"}>
                      <DropdownToggle
                        caret
                        color="default"
                        className="btn-neutral btn-icon"
                      >
                        {this.props.site.language
                          ? this.props.site.language.name
                          : "English"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {languages
                          .sort(function (a, b) {
                            if (a.name < b.name) return 1;
                            if (a.name > b.name) return -1;
                            return 0;
                          })
                          .map((language) => {
                            return (
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.site.language = language;
                                }}
                              >
                                {language.name}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href="https://www.maisondecode.com?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      Maison de Code
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.maisondecode.com/presentation?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://blog.maisondecode.com?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      Blog
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.maisondecode.com/license?ref=adpr-admin-footer"
                      target="_blank"
                    >
                      License
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default inject("user", "site")(observer(AuthFooter));
