/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
//mobx
import { observer, inject } from "mobx-react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// document head manager
import { Helmet } from "react-helmet";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.jsx";

const Benefits = ({ user }) => {
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [view] = React.useState("grid");

  React.useEffect(() => {
    let { id } = user.user.organization;
    getTeamMembers(id);
  }, []);

  const getTeamMembers = async (organization) => {
    let { id } = user.user.organization;
    let users;
    let options = {
      organization: id,
    };
    try {
      users = await API.graphql(
        graphqlOperation(queries.listUsersByOrganizationFirstName, options)
      );
    } catch (error) {
      users = error;
    } finally {
      console.log("Users ", users);
      if (users.data && users.data.listUsersByOrganizationFirstName.items) {
        let updatedArray = teamMembers.concat(
          users.data.listUsersByOrganizationFirstName.items
        );
        setTeamMembers(updatedArray);
      } else {
        console.log("Users ", users);
      }
    }
  };

  if (!user.user) return null;

  return (
    <>
      <Helmet>
        <title>asOne | Workforce | Benefits</title>
      </Helmet>
      <AlternativeHeader product={"Workforce"} name={"Benefits"} />
      <Container className="mt-6" fluid>
        <Card className="mb-4">
          <CardHeader className="col-auto">
            <h3 className="mb-0">All Benefits</h3>
          </CardHeader>

          <CardBody>
            {view === "grid" ? (
              <Row>
                <Col lg={"4"} md={"6"}>
                  <Link to={"/workforce/benefit/"} style={{ width: "100%" }}>
                    <Card>
                      <CardBody>
                        <Row className="align-items-center">
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a href={"/workforce/benefit/"}>Benfit Name</a>
                            </h4>
                            <p className="text-sm text-muted mb-0">
                              Benefit Company
                            </p>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            ) : (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Position
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Status
                    </th>
                    <th className="sort" data-sort="completion" scope="col">
                      Office Location
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {teamMembers
                    .sort(function (a, b) {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.lastName > b.lastName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((teamMember) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img alt="..." src={teamMember.profileImage} />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  {teamMember.firstName +
                                    " " +
                                    teamMember.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">{teamMember.position}</td>
                          <td>
                            {teamMember.online ? (
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                <span className="status">Online</span>
                              </Badge>
                            ) : (
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                <span className="status">Offline</span>
                              </Badge>
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link to="/app/locations">
                                {" "}
                                {teamMember.location.name}
                              </Link>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default inject("user")(observer(Benefits));
