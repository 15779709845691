import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { Card, CardHeader, Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { Auth } from "aws-amplify";

const NoAccess = ({ user }) => {
  const { _user, setUser } = React.useState();
  React.useEffect(() => {
    checkForUser();
  });

  const checkForUser = async () => {
    await Auth.currentAuthenticatedUser()
      .then((data) => {
        setUser(user.user);
        console.log("User", user.user);
      })
      .catch(() => {
        setUser(null);
        console.log("no user");
      });
  };

  if (!_user) {
    return <Redirect from="/no-access" to="/auth/login" />;
  }
  return (
    <div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
      <AdminNavbar
        {...this.props}
        theme={this.getNavbarTheme()}
        toggleSidenav={this.toggleSidenav}
        sidenavOpen={this.state.sidenavOpen}
        brandText={this.getBrandText(this.props.location.pathname)}
        backgroundColor={"bg-organization"}
      />
      <Container className="mt-2" fluid>
        <Card className="mb-4">
          <CardHeader className="col-auto">
            <h3 className="mb-0">You Do Not Have Access</h3>
          </CardHeader>
        </Card>
        >
      </Container>
    </div>
  );
};
export default inject("user")(observer(NoAccess));
