/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
//Routing
import { Link, Redirect } from "react-router-dom";
// store
import { inject, observer } from "mobx-react";
//AWS
import { Auth, API, graphqlOperation, I18n } from "aws-amplify";
import * as queries from "graphql/queries.js";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { strings } from "i18n/auth/index";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";

I18n.putVocabularies(strings);

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    newPassword: "",
    code: "",
    alert: null,
  };

  async signInWithGoogle(e, provider) {
    e.preventDefault();
    Auth.federatedSignIn({ provider: provider })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async signIn(e) {
    e.preventDefault();

    let { username, password } = this.state;
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "SMS_MFA") {
        // TODO: Setup a MFA component
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // TODO: Setup a Reset password component
        // const {requiredAttributes} = user.challengeParam;
      } else if (user.challengeName === "MFA_SETUP") {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        Auth.setupTOTP(user);
      } else {
        // The user directly signs in
        this.getUserInformation();
      }
    } catch (err) {
      console.log(err);
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        this.setState({
          alert: {
            message: I18n.get("error-UsernamePasswordIncorrect"),
            variant: "danger",
          },
          username: "",
          password: "",
        });
      } else if (err.code === "UserNotFoundException") {
        this.setState({
          alert: {
            message: I18n.get("error-UsernamePasswordIncorrect"),
            variant: "danger",
          },
          username: "",
          password: "",
        });
      } else {
        console.log(err);
      }
    }
  }

  async getUserInformation() {
    let id = await Auth.currentUserInfo().then((user) => {
      return user.attributes["custom:id"];
    });
    let userStore = this.props.user;
    let user;
    try {
      user = await API.graphql(graphqlOperation(queries.getUser, { id: id }));
    } catch (e) {
      user = e;
    } finally {
      userStore.user = user.data.getUser;
      if (user.data.getUser) {
        return this.setState({ redirectDashboard: true });
      }
    }
  }

  render() {
    let { redirectDashboard, alert } = this.state;
    if (redirectDashboard) return <Redirect to="/team" />;
    return (
      <>
        <AuthHeader
          title={I18n.get("login-title")}
          lead={I18n.get("login-subtitle")}
        />
        {alert && (
          <UncontrolledAlert
            color={alert.variant}
            style={{
              zIndex: 999,
              position: "fixed",
              top: 50,
              left: 50,
              right: 50,
            }}
          >
            <span className="alert-icon">
              {alert.variant === "danger" ? (
                <i class="fad fa-frown-open"></i>
              ) : (
                <i class="fad fa-thumbs-up"></i>
              )}
            </span>
            <span className="alert-text ml-1">{alert.message}</span>
          </UncontrolledAlert>
        )}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>{I18n.get("login-inputBoxText")}</small>
                  </div>
                  <div className="btn-wrapper row justify-content-center">
                    <Button
                      className="btn-neutral btn-icon"
                      color="black"
                      style={{ color: "#000" }}
                      onClick={(e) =>
                        this.signInWithGoogle(e, "SignInWithApple")
                      }
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fab fa-apple"></i>
                      </span>
                      <span className="btn-inner--text">
                        {I18n.get("Sign In With")} Apple
                      </span>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon mt-2"
                      color="default"
                      onClick={(e) => {
                        this.signInWithGoogle(e, "Google");
                      }}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={require("assets/img/icons/common/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">
                        {I18n.get("Sign In With")} Google
                      </span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>{I18n.get("Or sign in with credentials")}</small>
                  </div>
                  <Form onSubmit={this.signIn.bind(this)}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={I18n.get("usernamePlaceholder")}
                          type="username"
                          onChange={(e) => {
                            this.setState({ username: e.target.value });
                          }}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={I18n.get("password")}
                          type="password"
                          onChange={(e) => {
                            this.setState({ password: e.target.value });
                          }}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">
                          {I18n.get("Remember me")}
                        </span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="submit">
                        {I18n.get("Sign In")}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a className="text-light" href="/auth/forgot-password">
                    <small>{I18n.get("Forgot password?")}</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <Link to="/auth/register" className="text-light">
                    <small>{I18n.get("Create new account")}</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default inject("user")(observer(Login));
