/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Col, Card, CardImg, ListGroup, ListGroupItem } from "reactstrap";
// core components

class LocationCard extends React.Component {
  render() {
    let { location } = this.props;
    return (
      <>
        <Col xl="2" lg="4" md="6">
          <Card>
            <CardImg
              alt="..."
              src={require("assets/img/theme/img-1-1000x600.jpg")}
              top
            />

            <ListGroup flush>
              <ListGroupItem>{location.name}</ListGroupItem>
              <ListGroupItem>
                {location.lineOne} {location.lineTwo && location.lineTwo}{" "}
                {location.city} {location.state} {location.zip}{" "}
              </ListGroupItem>
              <ListGroupItem>
                {location.manager.firstName} {location.manager.lastName}
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </>
    );
  }
}

export default LocationCard;
