/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";

// AWS
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../../graphql/queries.js";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import IndexHeader from "components/Headers/IndexHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

var Prismic = require("prismic-javascript");
var apiEndpoint = "https://asone.cdn.prismic.io/api/v2";

class UseCase extends React.Component {
  state = {
    products: null,
  };

  async componentDidMount() {
    let products = null;
    await Prismic.getApi(apiEndpoint)
      .then(function (api) {
        return api.query(Prismic.Predicates.at("document.type", "product"));
      })
      .then(
        function (response) {
          products = response.results;
        },
        function (err) {
          console.log("Something went wrong: ", err);
        }
      );
    this.setState({ products });
    console.log(products);
  }

  render() {
    let { products } = this.state;
    return (
      <>
        <IndexNavbar />
        <div className="main-content">
          <IndexHeader />
          <section className="py-6 pb-9 bg-default">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="6">
                  <h2 className="display-3 text-white">
                    Title Of Use Case Document
                  </h2>
                  <p className="lead text-white">
                    asOne is cloud based work products like never seen before.
                    It was made for those who want to get things done and not be
                    held up by their technology. asOne can help a new
                    organization get started and optimize multi-million dollar
                    organizations. All of our products work together to help you
                    reduce workload and improve employee and client
                    satisfaction.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-6 pt-lg-0 mt--7">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <h1>Easy to use</h1>
                    <p>
                      Our platform is easy to use via inuitive and intelligent
                      design, allowing your team to get their work done faster.
                      Our platform is easy to use via inuitive and intelligent
                      design, allowing your team to get their work done faster.
                      Our platform is easy to use via inuitive and intelligent
                      design, allowing your team to get their work done faster.
                      Our platform is easy to use via inuitive and intelligent
                      design, allowing your team to get their work done faster.
                      Our platform is easy to use via inuitive and intelligent
                      design, allowing your team to get their work done faster.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-7 section-nucleo-icons bg-white overflow-hidden">
            <Container>
              <Row className="justify-content-center">
                <Col className="text-center" lg="8">
                  <h2 className="display-3">Third Party Integrations</h2>
                  <p className="lead">
                    Your organization has worked hard implementing other
                    services for your team, and we understand. Therefore, the
                    asOne team has worked hard to integrate other third party
                    platforms so your uptime is never compromised.
                  </p>
                  <div className="btn-wrapper">
                    <Button
                      color="info"
                      href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/icons?ref=adpr-index-page"
                      target="_blank"
                    >
                      See Our Integrations
                    </Button>
                    <Button
                      className="mt-3 mt-md-0"
                      color="default"
                      href="https://nucleoapp.com/?ref=1712"
                      target="_blank"
                    >
                      Work With Us
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="blur--hover">
                <a
                  href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/icons?ref=adpr-index-page"
                  target="_blank"
                >
                  <div className="icons-container blur-item mt-5">
                    <i className="icon">
                      <img
                        src={require("../../../components/Icon/asone.jsx")}
                      />
                    </i>

                    <i className="icon icon-sm fab fa-dropbox" />
                    <i className="icon icon-sm fab fa-google" />
                    <i className="icon icon-sm fab fa-apple" />

                    <i className="icon fab fa-mailchimp" />
                    <i className="icon fab fa-drupal" />
                    <i className="icon fab fa-wordpress" />

                    <i className="icon icon-sm ni ni-briefcase-24" />
                    <i className="icon icon-sm ni ni-building" />
                    <i className="icon icon-sm fab fa-trello" />

                    <i className="icon ni fab fa-instagram" />
                    <i className="icon ni fab fa-facebook" />
                    <i className="icon ni fab fa-twitter" />
                  </div>
                  <span className="blur-hidden h5 text-success">
                    See how we can help you with integrations
                  </span>
                </a>
              </div>
            </Container>
          </section>
          <section className="py-7 section-nucleo-icons bg-white overflow-hidden">
            <Container>
              <Row className="justify-content-center">
                <Col className="text-center" lg="8">
                  <h2 className="display-3">Let's Get Connected</h2>
                  <p className="lead"> Sign up for our emails </p>
                  <div className="btn-wrapper">
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={2}>
                          <Button
                            color="primary"
                            type="button"
                            onClick={this.validateCustomStylesForm}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default UseCase;
