/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// reactstrap components
import { Col, Button, Card, CardBody, Row } from "reactstrap";
// core components
import NoProfileImage from "./NoProfileImage";
class TeamMemberCard extends React.Component {
  render() {
    let { teamMember, showAdd, showView, to } = this.props;
    return (
      <>
        <Link
          to={to ? to : "/team-member/" + teamMember.id}
          style={{ width: "100%" }}
        >
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col className="col-auto">
                  {teamMember.profileImg ? (
                    <a
                      className="avatar avatar-xl rounded-circle"
                      href={"/app/team-member/" + teamMember.id}
                    >
                      <img alt="..." src={teamMember.profileImg} />
                    </a>
                  ) : (
                    <NoProfileImage
                      firstName={teamMember.firstName}
                      lastName={teamMember.lastName}
                      id={teamMember.id}
                    />
                  )}
                </Col>
                <div className="col ml--2">
                  <h4 className="mb-0">
                    <a href={"/app/team-member/" + teamMember.id}>
                      {teamMember.firstName + " " + teamMember.lastName}
                    </a>
                  </h4>
                  <p className="text-sm text-muted mb-0">
                    {teamMember.role ? teamMember.role.name : "Loading"}
                  </p>
                  {teamMember.online ? (
                    <>
                      <span className="text-success mr-1">●</span>
                      <small>Online</small>
                    </>
                  ) : (
                    <>
                      <span className="text-muted mr-1">●</span>
                      <small>Offline</small>
                    </>
                  )}
                </div>
                {showAdd && (
                  <Col className="col-auto">
                    <Button color="primary" size="sm" type="button">
                      Add
                    </Button>
                  </Col>
                )}
                {showView && (
                  <Col className="col-auto">
                    <Button color="primary" size="sm" type="button">
                      View
                    </Button>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Link>
      </>
    );
  }
}

export default TeamMemberCard;

TeamMemberCard.propTypes = {
  teamMember: PropTypes.object.isRequired,
  showAdd: PropTypes.bool,
};
