import { observable, computed, decorate } from "mobx";
import { create, persist } from "mobx-persist";

export class User {
  user = null;

  get getUser() {
    return this.user;
  }

  get isUser() {
    if (this.user !== null) {
      return true;
    }
    return false;
  }

  set setUser(user) {
    this.user = user;
  }

  get removeUser() {
    return this.user === null;
  }
}

decorate(User, {
  user: [persist("object"), observable],
  getUser: computed,
});

const UserStore = new User();
export default UserStore;

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

hydrate("user", UserStore)
  // post hydration
  .then(() => console.log("user hydrated"));
