/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
//mobx
import { observer, inject } from "mobx-react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
  Button,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// document head manager
import { Helmet } from "react-helmet";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import TeamMemberCard from "components/Reusable/TeamMemberCard";

class TeamMembers extends React.Component {
  async componentDidMount() {
    let users;
    console.log("User Organization", this.props.user.firstName);
    let { id } = this.props.user.organization;
    try {
      users = await API.graphql(
        graphqlOperation(queries.listUsers, {
          filter: {
            organization: { eq: id },
          },
        })
      );
    } catch (error) {
      users = error;
    } finally {
      if (users.data && users.data.listUsers.items) {
        console.log("Users ", users);
        this.setState({ teamMembers: users.data.listUsers.items });
      } else {
        console.log("Users ", users);
      }
    }
  }
  state = {
    isMore: true,
    view: "grid",
    teamMembers: [],
  };
  render() {
    let { teamMembers, view, isMore } = this.state;
    return (
      <>
        <Helmet>
          <title>asOne | Team Members</title>
        </Helmet>
        <SimpleHeader
          name="Team Members"
          parentName="Abstergo Labs"
          viewOptions
          new={() => {}}
          filter={() => {}}
          gridView={() => this.setState({ view: "grid" })}
          listView={() => this.setState({ view: "list" })}
        />
        <Container className="mt-6" fluid>
          <Card className="mb-4">
            <CardHeader className="col-auto">
              <h3 className="mb-0">All Team Members</h3>
            </CardHeader>

            <CardBody>
              {view === "grid" ? (
                <Row>
                  {teamMembers
                    .sort(function (a, b) {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.lastName > b.lastName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((teamMember) => {
                      return (
                        <Col lg="4" md="6">
                          <TeamMemberCard teamMember={teamMember} />
                        </Col>
                      );
                    })}
                </Row>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Position
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Status
                      </th>
                      <th className="sort" data-sort="completion" scope="col">
                        Office Location
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {teamMembers
                      .sort(function (a, b) {
                        if (a.firstName < b.firstName) {
                          return -1;
                        }
                        if (a.lastName > b.lastName) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((teamMember) => {
                        return (
                          <tr>
                            <th scope="row">
                              <Media className="align-items-center">
                                <a
                                  className="avatar rounded-circle mr-3"
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt="..."
                                    src={teamMember.profileImage}
                                  />
                                </a>
                                <Media>
                                  <span className="name mb-0 text-sm">
                                    {teamMember.firstName +
                                      " " +
                                      teamMember.lastName}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td className="budget">{teamMember.position}</td>
                            <td>
                              {teamMember.online ? (
                                <Badge color="" className="badge-dot mr-4">
                                  <i className="bg-warning" />
                                  <span className="status">Online</span>
                                </Badge>
                              ) : (
                                <Badge color="" className="badge-dot mr-4">
                                  <i className="bg-warning" />
                                  <span className="status">Offline</span>
                                </Badge>
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <Link to="/app/locations">
                                  {" "}
                                  {teamMember.location.name}
                                </Link>
                              </div>
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Action
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Another action
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Something else here
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
              {isMore && (
                <Button block color="primary" size="lg" type="button">
                  Load More Members
                </Button>
              )}
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}
export default inject("user")(observer(TeamMembers));
