/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
  Table,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// document head manager
import {Helmet} from 'react-helmet'
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import LocationCard from "components/Reusable/LocationCard"


class Settings extends React.Component {
  state = {
    isMore:false,
    view:"grid",
    locations: [
      {
        id:0,
        profileImage:"https://randomuser.me/api/portraits/men/1.jpg",
        name:'New York City (HQ)',
        address:"57th West 57th Street, New York, NY 10019",
        employees:[
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}
        ],
        manager: {
          firstName:'Barry',
          lastName:'Williams',
          profileImage:"https://randomuser.me/api/portraits/men/46.jpg",
        }
      },
      {
        id:1,
        name:'San Francisco Office',
        address:"57th West 57th Street, New York, NY 10019",
        employees:[
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
          {},{},{},{},{},{},{},{},{},{}
        ],
        manager: {
          firstName:'Katie',
          lastName:'Harper',
          profileImage:"https://randomuser.me/api/portraits/women/42.jpg",
        }
      },
      {
        id:2,
        name:'Austin Office',
        address:"1400 Lavaca Street Austin, TX 78701",
        employees:[
          {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        ],
        manager: {
          firstName:'Lawrence',
          lastName:'Brewer',
          profileImage:"https://randomuser.me/api/portraits/men/44.jpg",
        }
      },

    ]
  }
  render() {
    let { locations, view, isMore } = this.state
    return (
      <>
        <Helmet><title>asOne | Locations</title></Helmet>
        <SimpleHeader
          name="Locations"
          parentName="Abstergo Labs"
          viewOptions
          gridView={() => this.setState({view:'grid'})}
          listView={() => this.setState({view:'list'})}
        />
        <Container className="mt-6" fluid>
          <Card className="mb-4">
            <CardHeader className="col-auto">
              <h3 className="mb-0">All Locations</h3>
            </CardHeader>

            <CardBody>
              {view === "grid" ?
                <Row>
                  {locations.map((location) => {
                    return (<LocationCard location={location} />)
                  })}
                </Row>
                :
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Address
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Manager
                      </th>
                      <th className="sort" data-sort="completion" scope="col">
                        Employees
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {locations.map((location) => {
                    return (

                        <tr>
                          <th scope="row">
                            <Link to={'/app/location/' + location.id}>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="name mb-0 text-sm">
                                    {location.name}
                                  </span>
                                </Media>
                              </Media>
                            </Link>
                          </th>
                          <td className="budget">
                            <Link to={'/app/location/' + location.id}>
                              {location.address}
                            </Link>
                          </td>
                          <td>
                            <Link to={'/app/location/' + location.id}>
                              <Media className="align-items-center">
                                <a
                                  className="avatar rounded-circle mr-3"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  <img
                                    alt="..."
                                    src={location.manager.profileImage}
                                  />
                                </a>
                                <Media>
                                  <span className="name mb-0 text-sm">
                                    {location.manager.firstName + ' ' + location.manager.lastName}
                                  </span>
                                </Media>
                              </Media>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/app/location/' + location.id}>
                              <div className="d-flex align-items-center">
                                {location.employees.length}
                              </div>
                            </Link>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>

                    )
                  })}
                  </tbody>
                </Table>
              }
              {isMore &&
                <Button block color="primary" size="lg" type="button">
                  Load More Locations
                </Button>
              }

            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}
export default Settings;
