import Dashboard from "views/pages/converse/views/Dashboard.jsx";
const routes = [
  {
    name: "Circles",
    icon: "fad fa-circle text-converse",
    state: "dashboardsCollapse",
    path: "/circles",
    component: Dashboard,
    keepOrder: true,
    layout: "/converse",
  },
  {
    name: "Direct Messages",
    icon: "fad fa-comments-alt text-converse",
    state: "dashboardsCollapse",
    path: "/direct-messages",
    component: Dashboard,
    keepOrder: true,
    layout: "/converse",
  },
  {
    name: "Meetings",
    icon: "fad fa-webcam text-converse",
    state: "dashboardsCollapse",
    path: "/meetings",
    component: Dashboard,
    keepOrder: true,
    layout: "/converse",
  },
];

export const nonMenuRoutes = [
  {
    path: "/circle/:id",
    name: "Item",
    component: Dashboard,
    layout: "/converse",
  },
  {
    path: "/direct-messages/:id",
    name: "Order",
    component: Dashboard,
    layout: "/converse",
  },
  {
    path: "/meetings/:id",
    name: "Order",
    component: Dashboard,
    layout: "/converse",
  },
];

export default routes;
