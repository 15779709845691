/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// mobx
import { inject, observer } from "mobx-react";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import Icon from "../Icon/asone";
import languages from "../../languages.json";

import { I18n } from "aws-amplify";
import { strings } from "i18n/global/index";
I18n.putVocabularies(strings);

class IndexNavbar extends React.Component {
  render() {
    let {
      user: { user },
    } = this.props;
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-dark  navbar-transparent"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand to="/" tag={Link}>
              <Icon text />
            </NavbarBrand>
            <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <UncontrolledCollapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="3">
                    <Link to="/">
                      <Icon text={false} color={"#555"} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="9">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="mr-auto" navbar>
                <NavItem className="d-block d-lg-none">
                  {user ? (
                    <NavLink to="/team" tag={Link}>
                      <span className="nav-link-inner--text">
                        {I18n.get("Dashboard")}
                      </span>
                    </NavLink>
                  ) : (
                    <NavLink to="/auth/login" tag={Link}>
                      <span className="nav-link-inner--text">
                        {I18n.get("Login")}
                      </span>
                    </NavLink>
                  )}
                </NavItem>
                <NavItem>
                  <NavLink to="/platform" tag={Link}>
                    <span className="nav-link-inner--text">
                      {I18n.get("Platform")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/products" tag={Link}>
                    <span className="nav-link-inner--text">
                      {I18n.get("Products")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/use-cases/" tag={Link}>
                    <span className="nav-link-inner--text">
                      {I18n.get("Use Cases")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/auth/register" tag={Link}>
                    <span className="nav-link-inner--text">
                      {I18n.get("Sales")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/auth/register" tag={Link}>
                    <span className="nav-link-inner--text">
                      {I18n.get("Register")}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <hr className="d-lg-none" />
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://www.facebook.com/asoneplatform"
                    id="tooltip601201423"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square" />
                    <span className="nav-link-inner--text d-lg-none">
                      Facebook
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip601201423">
                    {I18n.get("Like us on Facebook")}
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://www.instagram.com/asoneplatform"
                    id="tooltip871243015"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                    <span className="nav-link-inner--text d-lg-none">
                      Instagram
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip871243015">
                    {I18n.get("Follow us on Instagram")}
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://twitter.com/asoneplatform"
                    id="tooltip366258619"
                    target="_blank"
                  >
                    <i className="fab fa-twitter-square" />
                    <span className="nav-link-inner--text d-lg-none">
                      Twitter
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip366258619">
                    {I18n.get("Follow us on Twitter")}
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem className="d-none d-lg-block ml-lg-4">
                  {user ? (
                    <Link to="/team/">
                      <Button className="btn-neutral btn-icon" color="default">
                        <span className="btn-inner--icon">
                          <i className="fas fa-user-circle mr-2" />
                        </span>
                        <span className="nav-link-inner--text">
                          {I18n.get("Dashboard")}
                        </span>
                      </Button>
                    </Link>
                  ) : (
                    <Link to="/auth/login">
                      <Button className="btn-neutral btn-icon" color="default">
                        <span className="btn-inner--icon">
                          <i className="fas fa-user-circle mr-2" />
                        </span>
                        <span className="nav-link-inner--text">
                          {I18n.get("Login")}
                        </span>
                      </Button>
                    </Link>
                  )}
                </NavItem>
                <NavItem className="d-block">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="default"
                      className="btn-neutral btn-icon"
                    >
                      {this.props.site.language
                        ? this.props.site.language.name
                        : "English"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {languages
                        .sort(function (a, b) {
                          if (a.name > b.name) return 1;
                          if (a.name < b.name) return -1;
                          return 0;
                        })
                        .map((language) => {
                          return (
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.site.language = language;
                              }}
                            >
                              {language.name}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default inject("user", "site")(observer(IndexNavbar));
