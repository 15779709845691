export const OnboardingQuery = /* GraphQL */ `
  query OnboardingQuery(
    $organization: String!
    $nameFilter: String
    $ascending: Boolean
    $first: Int
    $after: String
    $roleFilter: TableRoleFilterInput
    $userFilter: TableUserFilterInput
    $locationFilter: TableLocationFilterInput
  ) {
    listRolesByOrganization(
      organization: $organization
      filter: $roleFilter
      nameFilter: $nameFilter
    ) {
      items {
        id
        name
      }
      nextToken
    }
    listUsersByOrganizationFirstName(
      organization: $organization
      ascending: $ascending
      first: $first
      after: $after
      filter: $userFilter
    ) {
      items {
        id
        firstName
        lastName
        role {
          name
        }
        isTalent
      }
      nextToken
    }
    listLocationsByOrganization(
      organization: $organization
      filter: $locationFilter
    ) {
      items {
        id
        name
      }
      nextToken
    }

    listTalentsByOrganization(organization: $organization) {
      items {
        id
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
        }
      }
    }
    listOnboardingsByOrganization(organization: $organization) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      nextToken
    }

    enum: __type(name: "TaskStatus") {
      enumValues {
        name
      }
    }
  }
`;
