import Dashboard from "views/pages/workforce/views/Dashboard.jsx";
const routes = [
  {
    name: "Overview",
    icon: "fad fa-columns text-stockpile",
    state: "dashboardsCollapse",
    path: "/",
    component: Dashboard,
    keepOrder: true,
    layout: "/stockpile",
  },
  {
    name: "Invetory",
    icon: "fad fa-inventory text-stockpile",
    state: "dashboardsCollapse",
    path: "/inventory",
    component: Dashboard,
    keepOrder: true,
    layout: "/stockpile",
  },
  {
    name: "Orders",
    icon: "fad fa-receipt text-stockpile",
    state: "dashboardsCollapse",
    path: "/orders",
    component: Dashboard,
    keepOrder: true,
    layout: "/stockpile",
  },
];

export const nonMenuRoutes = [
  {
    path: "/item/:id",
    name: "Item",
    component: Dashboard,
    layout: "/stockpile",
  },
  {
    path: "/order/:id",
    name: "Order",
    component: Dashboard,
    layout: "/stockpile",
  },
];

export default routes;
