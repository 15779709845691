import React, { Component } from "react";

export default class Icon extends Component {
  render() {
    return (
      <svg
        width="auto"
        height={"40"}
        viewBox={"0 0 41 41"}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Logos"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-3"
            fill={this.props.color ? this.props.color : "#FFFFFF"}
          >
            <g id="Light-Icon">
              <g id="White" transform="translate(0.299270, 0.000000)">
                <path
                  d="M15.4728813,25.554769 C14.5540741,24.6430949 13.0622922,24.6417129 12.1420886,25.552005 L5.62898315,31.9857803 C4.70831405,32.8951511 4.70738315,34.3716117 5.62619042,35.2828252 C6.08605951,35.7388926 6.68975405,35.9669264 7.29298315,35.9669264 C7.89528133,35.9669264 8.49757951,35.739814 8.95698315,35.2855893 L15.4700886,28.8513533 C16.3907577,27.9419825 16.3921541,26.4655219 15.4728813,25.554769"
                  id="Fill-92"
                ></path>
                <path
                  d="M4.39878678,24.8517803 C3.47765224,23.9428702 1.98633587,23.9442522 1.06799405,24.8559264 L1.02098315,24.9024544 C0.102175873,25.8141286 0.103572236,27.2901286 1.02470678,28.1994994 C1.48457587,28.6532634 2.0864086,28.8803758 2.68824133,28.8803758 C3.29193587,28.8803758 3.89563042,28.6518814 4.35596496,28.1953533 L4.40297587,28.1488252 C5.32178315,27.2371511 5.31992133,25.7611511 4.39878678,24.8517803"
                  id="Fill-93"
                ></path>
                <path
                  d="M27.7376086,36.0788702 L27.5961104,35.9853533 C26.5139286,35.2713084 25.0514704,35.5606117 24.3300159,36.6321398 C23.6085613,37.7032073 23.9008668,39.1506455 24.9830486,39.8646904 L25.1245468,39.9582073 C25.5262341,40.2230949 25.9800522,40.350241 26.4287504,40.350241 C27.1897686,40.350241 27.9372886,39.9858477 28.3906413,39.3118814 C29.1125613,38.240814 28.8202559,36.7929151 27.7376086,36.0788702"
                  id="Fill-94"
                ></path>
                <path
                  d="M39.9208813,12.9995556 C39.0006777,12.0888028 37.5098268,12.0888028 36.5900886,12.9995556 L12.9175359,36.4285219 C11.9977977,37.338814 11.9977977,38.8152747 12.9175359,39.7255668 C13.377405,40.1802522 13.9806341,40.4082859 14.5833977,40.4082859 C15.1861613,40.4082859 15.788925,40.1802522 16.2487941,39.7255668 L39.9208813,16.2961398 C40.8406195,15.3858477 40.8406195,13.9098477 39.9208813,12.9995556"
                  id="Fill-95"
                ></path>
                <path
                  d="M36.5309759,24.7255556 L33.3393541,27.8843983 C32.4196159,28.7946904 32.4196159,30.2711511 33.3393541,31.1814432 C33.7992231,31.6365893 34.4015213,31.8641623 35.0047504,31.8641623 C35.6075141,31.8641623 36.2102777,31.6365893 36.6701468,31.1814432 L39.8622341,28.0226005 C40.7815068,27.1123084 40.7815068,25.6358477 39.8622341,24.7255556 C38.941565,23.8152634 37.4507141,23.8152634 36.5309759,24.7255556"
                  id="Fill-96"
                ></path>
                <path
                  d="M2.40571042,17.5035668 C3.00847405,17.5035668 3.61123769,17.2759938 4.07110678,16.8208477 L10.9137541,10.0484769 C11.8334922,9.13818478 11.8339577,7.66218478 10.9137541,6.75189264 C9.99401587,5.84113983 8.50269951,5.84160051 7.58296133,6.75189264 L0.740314054,13.5238028 C-0.179424127,14.4340949 -0.179889582,15.9100949 0.740314054,16.8208477 C1.20018315,17.2759938 1.80294678,17.5035668 2.40571042,17.5035668"
                  id="Fill-97"
                ></path>
                <path
                  d="M18.4997322,22.5557803 C18.9600668,23.0109264 19.5628304,23.2384994 20.1655941,23.2384994 C20.7683577,23.2384994 21.3711213,23.0109264 21.8314559,22.5557803 L35.4455359,9.08106118 C36.3652741,8.17076905 36.3652741,6.69430837 35.4455359,5.78401624 C34.5253322,4.8737241 33.0344813,4.8737241 32.1142777,5.78401624 L18.4997322,19.2587353 C17.5804595,20.1690275 17.5804595,21.6454881 18.4997322,22.5557803"
                  id="Fill-98"
                ></path>
                <path
                  d="M7.43029224,21.8569376 C7.88969587,22.311623 8.49245951,22.539196 9.09522315,22.539196 C9.69798678,22.539196 10.3012159,22.3111623 10.761085,21.8555556 L28.2738122,4.51117354 C29.1935504,3.60042073 29.193085,2.12442073 28.2724159,1.21458927 C27.3531431,0.304297138 25.8613613,0.304757812 24.9416231,1.21551062 L7.42889587,18.5598926 C6.50962315,19.4706455 6.5100886,20.9466455 7.43029224,21.8569376"
                  id="Fill-99"
                ></path>
                <path
                  d="M15.1247213,4.90320725 C16.4256668,4.90320725 17.4803868,3.85978028 17.4803868,2.57219601 L17.4803868,2.33863422 C17.4803868,1.05104995 16.4256668,0.0076229804 15.1247213,0.0076229804 C13.8242413,0.0076229804 12.7695213,1.05104995 12.7695213,2.33863422 L12.7695213,2.57219601 C12.7695213,3.85978028 13.8242413,4.90320725 15.1247213,4.90320725"
                  id="Fill-100"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
