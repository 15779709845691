/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
//mobx
import { observer, inject } from "mobx-react";
// reactstrap components
import { Card, CardHeader, CardBody, Container } from "reactstrap";
// document head manager
import { Helmet } from "react-helmet";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.jsx";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Users = ({ user, history }) => {
  const [teamMembers, setTeamMembers] = React.useState([]);

  React.useEffect(() => {
    let { id } = user.user.organization;
    getTeamMembers(id);
  }, []);

  const getTeamMembers = async (organization) => {
    let { id } = user.user.organization;
    let users;
    let options = {
      organization: id,
    };
    try {
      users = await API.graphql(
        graphqlOperation(queries.listUsersByOrganizationFirstName, options)
      );
    } catch (error) {
      users = error;
    } finally {
      if (users.data && users.data.listUsersByOrganizationFirstName.items) {
        let updatedArray = teamMembers.concat(
          users.data.listUsersByOrganizationFirstName.items
        );
        setTeamMembers(updatedArray);
      } else {
        console.log("Error ", users);
      }
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/admin/user/${row.id}`);
    },
  };
  if (!user.user) return null;
  return (
    <>
      <Helmet>
        <title>asOne | Users</title>
      </Helmet>
      <AlternativeHeader
        name="Team Members"
        product={user.user.organization.name}
        viewOptions
        new={() => {}}
        filter={() => {}}
      />
      <Container className="mt-2" fluid>
        <Card className="mb-4">
          <CardHeader className="col-auto">
            <h3 className="mb-0">All Users</h3>
          </CardHeader>

          <CardBody>
            <ToolkitProvider
              data={teamMembers}
              keyField="name"
              columns={[
                {
                  dataField: "firstName",
                  text: "firstName",
                  sort: true,
                },
                {
                  dataField: "lastName",
                  text: "lastName",
                  sort: true,
                },
                {
                  dataField: "location.name",
                  text: "Office",
                  sort: true,
                },

                {
                  dataField: "role.name",
                  text: "Position",
                  sort: true,
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1"
                  >
                    <label>
                      Search:
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </label>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                    rowEvents={rowEvents}
                  />
                </div>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default inject("user")(observer(Users));
