import React from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";

// core componentes
import ProductHeader from "../../../components/Headers/ProductHeader";

// Prismic
var Prismic = require("prismic-javascript");
var apiEndpoint = "https://asone.cdn.prismic.io/api/v2";

export default function ({ match }) {
  React.useEffect(() => {
    getProduct();
  }, []);
  const [product, setProduct] = React.useState(null);
  const [features, setFeatures] = React.useState([]);

  const getProduct = async () => {
    let { uuid } = match.params;
    await Prismic.getApi(apiEndpoint)
      .then(function (api) {
        api.getByUID("product", uuid).then(function (document) {
          setProduct(document);
          getFeatures(document);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFeatures = async (product) => {
    let { id } = product;
    await Prismic.getApi(apiEndpoint)
      .then(function (api) {
        api
          .query([
            Prismic.Predicates.at("document.type", "product-feature"),
            Prismic.Predicates.at("my.product-feature.product", id),
          ])
          .then(function (response) {
            setFeatures(response.results);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!product) return null;
  console.log(product.id);
  return (
    <>
      <div className="main-content">
        {product && (
          <>
            <ProductHeader product={product} />
            <section id="features" className="py-6 pb-9 bg-default">
              <Container fluid>
                <Row className="justify-content-center text-center">
                  <Col md="6">
                    <h2 className="display-3 text-white">
                      {product.data.title[0].text}
                    </h2>
                    <p className="lead text-white">
                      {product.data.text[0].text}
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="section section-lg pt-lg-0 mt--7">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Row>
                      {features &&
                        features
                          .sort(function (a, b) {
                            if (a.uid < b.uid) {
                              return -1;
                            }
                            if (a.uid > b.uid) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((feature) => {
                            return (
                              <Col lg="4">
                                <Card
                                  className="card-lift--hover shadow border-0"
                                  style={{ height: "90%" }}
                                >
                                  <CardBody className="py-5">
                                    <div
                                      className="icon icon-shape text-white rounded-circle mb-4"
                                      style={{
                                        background: `linear-gradient(87deg, ${product.data.color_secondary} 0, ${product.data.color_primary} 100%)`,
                                      }}
                                    >
                                      <i
                                        className={`fad ${feature.data.font_awesome}`}
                                      />
                                    </div>
                                    <h4
                                      className="h3 text-uppercase"
                                      style={{
                                        color: product.data.color_secondary,
                                      }}
                                    >
                                      {feature.data.title[0].text}
                                    </h4>
                                    <p className="description mt-3">
                                      {feature.data.text[0] &&
                                        feature.data.text[0].text}
                                    </p>
                                    <div>
                                      {feature.data.concepts &&
                                        feature.data.concepts.map((concept) => {
                                          return (
                                            <Badge
                                              style={{
                                                backgroundColor:
                                                  product.data.color_secondary,
                                                color: "#fff",
                                              }}
                                              pill
                                            >
                                              {concept.concept}
                                            </Badge>
                                          );
                                        })}
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            );
                          })}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="py-7 section-nucleo-icons bg-white overflow-hidden">
              <Container>
                <Row className="row-grid m-2 justify-content-center">
                  <Col className="text-center" lg="8">
                    <h2 className="display-3">Pricing That Works For You</h2>
                    <p className="lead">
                      asOne does not believe in all or nothing pricing
                      platforms, so we give you the freedom to pick and choose
                      the products you need and how many of your team members
                      you want to use that product.
                    </p>
                  </Col>
                  <Col lg="12" className="m-2">
                    <div className="pricing card-group flex-column flex-md-row mb-3">
                      {product.data.pricing &&
                        product.data.pricing.map((pricing, index) => {
                          return (
                            <>
                              {index % 2 === 0 ? (
                                <Card className="card-pricing border-0 text-center mb-4">
                                  <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                                      {index !== 0 &&
                                        index !==
                                          product.data.pricing.length - 1 && (
                                          <>
                                            {pricing.min}-{pricing.max}{" "}
                                            {pricing.name_plural}
                                          </>
                                        )}

                                      {index === 0 && (
                                        <>
                                          First {pricing.max}{" "}
                                          {pricing.name_plural}
                                        </>
                                      )}
                                      {index ===
                                        product.data.pricing.length - 1 && (
                                        <>
                                          {pricing.min}+ {pricing.name_plural}
                                        </>
                                      )}
                                    </h4>
                                  </CardHeader>
                                  <CardBody className="px-lg-4">
                                    <div className="display-3">
                                      ${pricing.amount}
                                    </div>
                                    <span className="text-muted">
                                      per user / month
                                    </span>
                                    <br />
                                    <Button
                                      className="m-3"
                                      color="info"
                                      type="button"
                                    >
                                      Sign Up Now
                                    </Button>
                                  </CardBody>
                                </Card>
                              ) : (
                                <Card
                                  className={`card-pricing bg-gradient-${product.uid} zoom-in shadow-lg rounded border-0 text-center mb-4`}
                                >
                                  <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                                      {index !== 0 &&
                                        index !==
                                          product.data.pricing.length - 1 && (
                                          <>
                                            {pricing.min}-{pricing.max}{" "}
                                            {pricing.name_plural}
                                          </>
                                        )}

                                      {index === 0 && (
                                        <>
                                          First {pricing.min}{" "}
                                          {pricing.name_plural}
                                        </>
                                      )}
                                      {index ===
                                        product.data.pricing.length - 1 && (
                                        <>
                                          {pricing.min}+ {pricing.name_plural}
                                        </>
                                      )}
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="display-3 text-white">
                                      ${pricing.amount}
                                    </div>
                                    <span className="text-white">
                                      per user / month
                                    </span>
                                    <br />
                                    <Button
                                      className="m-3"
                                      color="secondary"
                                      type="button"
                                    >
                                      Sign Up Now
                                    </Button>
                                  </CardBody>
                                </Card>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </Col>
                </Row>

                <Button
                  block
                  className="btn-neutral"
                  color="default"
                  href="/products/pricing-calculator"
                >
                  Use Pricing Calculator
                </Button>
              </Container>
            </section>
          </>
        )}
      </div>
    </>
  );
}
