/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// document head manager
import {Helmet} from 'react-helmet'
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import TeamMemberCard from "components/Reusable/TeamMemberCard"

class Companies extends React.Component {
  state = {
    isMore:true,
    view:"grid",
    teamMembers: [
      {
        profileImage:"https://randomuser.me/api/portraits/men/1.jpg",
        firstName:"Jesse",
        lastName:"Terry",
        position:"UI and UX Designer",
        online:true,
        location: {
          id:"1",
          name:"New York City (HQ)"
        }
      },
      {
        profileImage:"https://randomuser.me/api/portraits/men/46.jpg",
        firstName:"Barry",
        lastName:"Williams",
        position:"Director of Marketing",
        online:true,
        location: {
          id:"1",
          name:"New York City (HQ)"
        }
      },
      {
        firstName:"Timmothy",
        lastName:"Ford",
        profileImage:"https://randomuser.me/api/portraits/men/4.jpg",
        position:"Game Level Designer",
        online:false,
        location: {
          id:"2",
          name:"San Francisco Office"
        }
      },
      {
        firstName:"Brandie",
        lastName:"Mason",
        profileImage:"https://randomuser.me/api/portraits/women/63.jpg",
        position:"Character Designer",
        online:true,
        location: {
          id:"2",
          name:"Austin Office"
        }
      },
      {
        firstName:"Enrique",
        lastName:"Baker",
        profileImage:"https://randomuser.me/api/portraits/men/85.jpg",
        position:"Software Engineer",
        online:true,
        location: {
          id:"1",
          name:"New York City (HQ)"
        }
      },
      {
        firstName:"Debra",
        lastName:"Carlson",
        profileImage:"https://randomuser.me/api/portraits/women/71.jpg",
        position:"Game Project Manager",
        online:true,
        location: {
          id:"1",
          name:"New York City (HQ)"
        }
      },
    ]
  }
  render() {
    let { teamMembers, view, isMore } = this.state
    return (
      <>
        <Helmet><title>asOne | Companies</title></Helmet>
        <SimpleHeader
          name="Companies"
          parentName="Abstergo Labs"
          viewOptions
          new={() => {}}
          filter={() => {}}
          gridView={() => this.setState({view:'grid'})}
          listView={() => this.setState({view:'list'})}
        />
        <Container className="mt-6" fluid>
          <Card className="mb-4">
            <CardHeader className="col-auto">
              <h3 className="mb-0">All Companies</h3>
            </CardHeader>
            <CardBody>
              {view === "grid" ?
                <Row>
                  {teamMembers.sort(function(a,b) {
                    if(a.firstName < b.firstName) {return -1}
                    if(a.lastName > b.lastName) {return 1}
                    return 0;
                  }).map((teamMember) => {
                    return (
                      <TeamMemberCard teamMember={teamMember} />
                    )
                  })}
                </Row>
                :
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Position
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Status
                      </th>
                      <th className="sort" data-sort="completion" scope="col">
                        Office Location
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {teamMembers.sort(function(a,b) {
                      if(a.firstName < b.firstName) {return -1}
                      if(a.lastName > b.lastName) {return 1}
                      return 0;
                    }).map((teamMember) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={teamMember.profileImage}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  {teamMember.firstName + ' ' + teamMember.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">{teamMember.position}</td>
                          <td>
                            {teamMember.online ?
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                <span className="status">Online</span>
                              </Badge>
                              :
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                <span className="status">Offline</span>
                              </Badge>
                            }
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link to="/app/locations" > {teamMember.location.name}</Link>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </Table>
              }
              {isMore &&
                <Button block color="primary" size="lg" type="button">
                  Load More Members
                </Button>
              }

            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}
export default Companies;
