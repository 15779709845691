/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Routing
import { Link, Redirect } from "react-router-dom";
// AWS Authentication and API
import { Auth, API, graphqlOperation, I18n } from "aws-amplify";
import * as queries from "graphql/queries.js";
// mobx
import { inject, observer } from "mobx-react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";
// document head manager
import { Helmet } from "react-helmet";
// translaton
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { strings } from "i18n/auth/index";
// core components

I18n.putVocabularies(strings);

class ForgotPassword extends React.Component {
  state = {
    view: "username",
    username: "",
    newPassword: "",
    authCode: "",
    authData: "",
    alert: "",
    redirect: false,
    user: null,
  };

  async componentDidMount() {
    let {
      params: { id },
    } = this.props.match;
    let _user;
    try {
      _user = await API.graphql(graphqlOperation(queries.getUser, { id: id }));
    } catch (error) {
      _user = error;
    } finally {
      if (_user.data && _user.data.getUser) {
        this.setState({ user: _user.data.getUser });
      }
    }
  }

  async _forgotPassword(e) {
    e.preventDefault();
    console.log("in side forgot password function");
    let { username } = this.state;
    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({ authData: data });
        console.log(data);
        this.setState({
          alert: {
            variant: "default",
            message: I18n.get("We have sent you a verification code"),
          },
        });
        this.setState({ view: "reset" });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ alert: err });
      });
  }

  _resetPassword(e) {
    let { username, authCode, newPassword } = this.state;
    e.preventDefault();
    this.setState({ alert: null });
    Auth.forgotPasswordSubmit(username, authCode, newPassword)
      .then((data) => {
        this.props.history.push("/auth/login");
      })
      .catch((err) => {
        this.setState({ alert: err });
      });
  }

  _resendVerificationCode(e) {
    let { username } = this.state;
    e.preventDefault();
    this.setState({ alert: null });
    Auth.resendSignUp(username)
      .then(() => {
        this.setState({
          alert: {
            variant: "primary",
            message: I18n.get("forgotPassword-viewUsername-successMessage"),
          },
        });
      })
      .catch((e) => {
        this.setState({
          alert: {
            variant: "danger",
            message:
              "There was an error sending the verification code. Please try again",
          },
        });
      });
  }

  render() {
    let {
      view,
      username,
      newPassword,
      authCode,
      authData,
      alert,
      redirect,
    } = this.state;
    if (redirect) return <Redirect to="/team" />;
    return (
      <>
        <Helmet>
          <title>asOne | Reset Password</title>
        </Helmet>
        <AuthHeader
          title={I18n.get("forgotPassword-title")}
          lead={I18n.get("forgotPassword-subtitle")}
        />
        {alert && (
          <UncontrolledAlert
            color={alert.variant}
            style={{
              zIndex: 999,
              position: "fixed",
              top: 50,
              left: 50,
              right: 50,
            }}
          >
            <span className="alert-icon">
              {alert.variant === "danger" ? (
                <i class="fad fa-frown-open"></i>
              ) : (
                <i class="fad fa-thumbs-up"></i>
              )}
            </span>
            <span className="alert-text ml-1">{alert.message}</span>
          </UncontrolledAlert>
        )}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                {view === "username" && (
                  <>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>
                          {I18n.get("forgotPassword-viewUsername-inputBoxText")}
                        </small>
                      </div>
                      <Form
                        role="form"
                        onSubmit={this._forgotPassword.bind(this)}
                      >
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedUsername,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-at"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder={I18n.get(
                                "forgotPassword-viewUsername-usernamePlaceholder"
                              )}
                              value={username}
                              onChange={(e) => {
                                this.setState({ username: e.target.value });
                              }}
                              type="text"
                              onFocus={() =>
                                this.setState({ focusedUsername: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedUsername: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                          <Button className="mt-4" color="info" type="submit">
                            {I18n.get("forgotPassword-viewUsername-send-code")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </>
                )}

                {view === "reset" && (
                  <>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>{I18n.get("Verification Code")}</small>
                        <br />
                        <small>
                          {authData.CodeDeliveryDetails.DeliveryMedium ===
                          "EMAIL"
                            ? I18n.get("verificationEmailSent")
                            : I18n.get("verificationSMSSent")}{" "}
                          {authData.CodeDeliveryDetails.Destination}{" "}
                          {I18n.get("enterCodeAndPassword")}
                        </small>
                      </div>
                      <Form
                        role="form"
                        onSubmit={this._resetPassword.bind(this)}
                      >
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedCode,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-shield-alt"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder={I18n.get("Verification Code")}
                              type="code"
                              value={authCode}
                              onChange={(e) => {
                                this.setState({ authCode: e.target.value });
                              }}
                              onFocus={() =>
                                this.setState({ focusedCode: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedCode: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedCode,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder={I18n.get("New Password")}
                              type="code"
                              value={newPassword}
                              onChange={(e) => {
                                this.setState({ newPassword: e.target.value });
                              }}
                              onFocus={() =>
                                this.setState({ focusedCode: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedCode: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>

                        <Link
                          className="text-muted"
                          onClick={(e) => {
                            this._resendVerificationCode(e);
                          }}
                        >
                          <small>{I18n.get("Resend Verification Code")}</small>
                        </Link>

                        <div className="text-center">
                          <Button className="mt-4" color="info" type="submit">
                            {I18n.get("Reset Password")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default inject("user")(observer(ForgotPassword));
