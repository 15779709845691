/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

//AWS Amplify
import Amplify from "aws-amplify";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import "./assets/css/asone-colors.css";

import Layouts from "./layouts.jsx";

import { Provider } from "mobx-react";
import UserStore from "mobx/UserStore.js";
import SiteStore from "mobx/SiteStore.js";

Amplify.configure({
  aws_project_region: "us-east-1",
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: "us-east-1:e8eea6c0-f9ce-4dc7-8d0b-7dd0a2b22b39",
    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_gtMtXg3sp",
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: "7hkedcuksjmvpaor2lmuhch8p5",
    // oauth: {
    //   domain: "http://localhost:3000",
    //   scope: [
    //     "phone",
    //     "email",
    //     "profile",
    //     "openid",
    //     "aws.cognito.signin.user.admin",
    //   ],
    //   redirectSignIn: "http://localhost:3000/auth/login",
    //   redirectSignOut: "http://localhost:3000/auth/logout",
    //   responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
  },
  Storage: {
    AWSS3: {
      bucket: "asone.team",
    },
  },
  API: {
    aws_appsync_graphqlEndpoint:
      "https://pfzvy6e3czftvporthc5k7kxs4.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "API_KEY",
    aws_appsync_apiKey: "da2-vbrvqflozvdp3mgcyakpf2uphy",
  },
});

ReactDOM.render(
  <Provider user={UserStore} site={SiteStore}>
    <Layouts />
  </Provider>,
  document.getElementById("root")
);
