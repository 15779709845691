/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// Routing
import { Link, Redirect } from "react-router-dom"
// AWS Authentication and API
import { Auth, API, graphqlOperation } from "aws-amplify"
import * as mutations from "graphql/mutations.js"
// Stripe Payment
import {StripeProvider, Elements} from 'react-stripe-elements';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";
// document head manager
import {Helmet} from 'react-helmet'
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import PaymentForm from "components/Payments/PaymentForm.jsx";


class Register extends React.Component {
  state = {
    view:"information",
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    username: "",
    password: "",
    authCode:"",
    alert:"",
    redirect:false,
  };

  //TODO: Add User to userStore
  async _createAccount(e) {
    let {username, password, email} = this.state
    e.preventDefault()
    this.setState({alert:null})
    Auth.signUp({ username, password, attributes: { email }})
    .then((user) => {
      this.setState({userID: user.userSub, view:'verificationCode'})
    })
    .catch((e) => {
      switch (e.code) {
        case 'UsernameExistsException':
          this.setState({
            alert: {variant:'danger', message:'This username or email already exists.'},
            username:'',
            focusedUsername:true
          })
          break;
        case 'InvalidParameterException':
          this.setState({
            alert: {variant:'danger', message:'Your password is not strong enough. Please make sure all the requirements are blue'},
            password:'',
            focusPassword:true
          })
          break;
        case 'InvalidPasswordException':
          this.setState({
            alert: {variant:'danger', message:'Your password is not strong enough. Please make sure all the requirements are blue'},
            password:'',
            focusPassword:true
          })
          break;
        default:
          this.setState({
            alert: {variant:'danger', message:'An unknown error occured. Please try again'},
          })
          break;
      }
    })
  }

   _verificationCode(e) {
    let {username, authCode, password} = this.state
    e.preventDefault()
    this.setState({alert:null})
    Auth.confirmSignUp(username, authCode)
    .then(() => {
      Auth.signIn(username, password)
      .then((user) => {
        console.log(user)
        this.setState({view:'stripe'})
      })
    })
    .catch(e => {
      console.log(e)
       switch (e.code) {
         case 'CodeMismatchException':
           this.setState({alert: {variant:'danger', message:'Incorrect verification code. Please try again'}})
           break;
         default:
           this.setState({alert: {variant:'danger', message:'Something went wrong. Please try again'}})
           break
       }
    });
  }

  _handleStripe(stripe) {
    if(stripe.customer.id) {
      this.setState({stripeID: stripe.customer.id})
      this._organizationSetup()
    } else {
      this.setState({alert: {
        variant:'danger', message:'There was an error recording your payment method. Please try again.'
      }})
    }
  }

  async _organizationSetup() {
    let { organization, stripeID } = this.state
    let organizationObject

    let input = {
      name:organization,
      stripe:stripeID,
    }

    try {
      organizationObject = await API.graphql(graphqlOperation(mutations.createOrganization, {input: input}))
    } catch (e) {
      organizationObject = e
    } finally {
      console.log(organizationObject)
      if(organizationObject.data.createOrganization.id) {
        this._userSetup(organizationObject.data.createOrganization.id)
      }
    }
  }

  //TODO: Add User to userStore
  async _userSetup(organizationID) {
    let { firstName, lastName, email, userID } = this.state
    let user
    let input = {
      id:userID,
      organization:organizationID,
      firstName,
      lastName,
      email,
    }
    console.log(input)

    try {
      user = await API.graphql(graphqlOperation(mutations.createUser, {input:input}))
    } catch (e) {
      user = e
    } finally {
      console.log("User to created in DynamoDB", user)
      if(user.data.createUser) {
        this._policySetup(user.data.createUser.id, organizationID)
      }
    }
  }

  async _policySetup(userID, organizationID) {
    let policy;
    let input = {
      user:userID,
      organization:organizationID,
      isMasterAccount:true,
      isSuperAdmin:true,
    }

    try {
      policy = await API.graphql(graphqlOperation(mutations.createPolicy, {input:input}))
    } catch (e) {
      policy = e
    } finally {
      console.log(policy)
      if(policy.data.createPolicy) {
        this.setState({redirect:true})
      } else {
        // TODO: Delete User, Organization, and Stripe
      }
    }
  }

  _resendVerificationCode(e) {
   let {username} = this.state
   e.preventDefault()
   this.setState({alert:null})
   Auth.resendSignUp(username).then(() => {
     this.setState({
       alert: {variant:'primary', message:'Verification code was resent successfully'},
     })
   }).catch(e => {
      this.setState({alert: {variant:'danger', message:'There was an error sending the verification code. Please try again'}})
   });
 }

  render() {
    let { view, firstName, lastName, organization, email, username, password, authCode, alert, redirect } = this.state
    if(redirect) return ( <Redirect to="/app/dashboard" /> )
    return (
      <>
        <Helmet><title>asOne | Register</title></Helmet>
        <AuthHeader
          title="Create an account"
          lead="You are creating a new organizational account. If your team is already using asOne, please request an invite from your team to join."
        />
        {alert &&
          <UncontrolledAlert color={alert.variant} style={{zIndex:999, position:'fixed', top:50, left: 50, right: 50}}>
            <span className="alert-icon">
              {alert.variant === "danger" ? <i class="fad fa-frown-open"></i> : <i class="fad fa-thumbs-up"></i> }
            </span>
            <span className="alert-text ml-1">
              {alert.message}
            </span>
          </UncontrolledAlert>
        }
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                {view === 'information' &&
                  <>
                    <CardHeader className="bg-transparent pb-5">
                      <div className="text-muted text-center mt-2 mb-4">
                        <small>Sign up with</small>
                      </div>
                      <div className="text-center">
                        <Button
                          className="btn-neutral btn-icon mr-4"
                          color="black"
                          href="#pablo"
                          style={{color:"#000"}}
                          onClick={e => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i class="fab fa-apple"></i>
                          </span>
                          <span className="btn-inner--text">Sign In With Apple</span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon"
                          color="default"
                          onPress={() => Auth.federatedSignIn({ provider: "SignInWithApple" })}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign up with credentials</small>
                      </div>
                      <Form role="form" onSubmit={this._createAccount.bind(this)}>
                        <Row>
                          <Col>
                            <FormGroup
                              className={classnames({
                                focused: this.state.focusedFirstName
                              })}
                            >
                              <InputGroup className="input-group-merge input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i class="fad fa-id-card"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  required
                                  value={firstName}
                                  onChange={(e) => {this.setState({firstName:e.target.value})}}
                                  placeholder="First Name"
                                  type="text"
                                  onFocus={() => this.setState({ focusedFirstName: true })}
                                  onBlur={() => this.setState({ focusedFirstName: false })}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup
                              className={classnames({
                                focused: this.state.focusedLastName
                              })}
                            >
                              <InputGroup className="input-group-merge input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i class="fad fa-id-card"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  required
                                  placeholder="Last Name"
                                  type="text"
                                  value={lastName}
                                  onChange={(e) => {this.setState({lastName:e.target.value})}}
                                  onFocus={() => this.setState({ focusedLastName: true })}
                                  onBlur={() => this.setState({ focusedLastName: false })}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedOrganization
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-building"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Organization Name"
                              type="company"
                              value={organization}
                              onChange={(e) => {this.setState({organization:e.target.value})}}
                              onFocus={() => this.setState({ focusedOrganization: true })}
                              onBlur={() => this.setState({ focusedOrganization: false })}
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedEmail
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-envelope"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              value={email}
                              placeholder="Email"
                              type="email"
                              onChange={(e) => {this.setState({email:e.target.value})}}
                              onFocus={() => this.setState({ focusedEmail: true })}
                              onBlur={() => this.setState({ focusedEmail: false })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedUsername
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-at"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Username"
                              value={username}
                              onChange={(e) => {this.setState({username:e.target.value})}}
                              type="text"
                              onFocus={() => this.setState({ focusedUsername: true })}
                              onBlur={() => this.setState({ focusedUsername: false })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedPassword
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-unlock-alt"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Password"
                              type="password"
                              value={password}
                              onChange={(e) => {this.setState({password:e.target.value})}}
                              onFocus={() => this.setState({ focusedPassword: true })}
                              onBlur={() => this.setState({ focusedPassword: false })}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-muted font-italic">
                          <small>
                            password strength:{" "}
                            <span className="text-success font-weight-700">
                              strong
                            </span>
                          </small>
                        </div>
                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                required
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                                <span className="text-muted">
                                  I agree with the{" "}
                                  <a
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-center">
                          <Button className="mt-4" color="info" type="submit" >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </>
                }

                {view === 'verificationCode' &&
                  <>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Verification Code</small>
                        <br/>
                        <small>Thanks {firstName}! We just need to verify your email. We sent a verification code to {email}</small>
                      </div>
                      <Form role="form" onSubmit={this._verificationCode.bind(this)}>
                          <FormGroup
                            className={classnames({
                              focused: this.state.focusedCode
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i class="fad fa-shield-alt"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                required
                                placeholder="Verification Code"
                                type="code"
                                value={authCode}
                                onChange={(e) => {this.setState({authCode:e.target.value})}}
                                onFocus={() => this.setState({ focusedCode: true })}
                                onBlur={() => this.setState({ focusedCode: false })}
                              />
                            </InputGroup>
                          </FormGroup>

                          <Link className="text-muted" onClick={(e) => {this._resendVerificationCode(e)}} >
                            <small>
                              Resend Verification Code
                            </small>
                          </Link>

                          <div className="text-center">
                            <Button className="mt-4" color="info" type="submit" >
                              Verify Account
                            </Button>
                          </div>
                      </Form>
                    </CardBody>
                  </>
                }

                {view === 'stripe' &&
                  <StripeProvider apiKey="pk_test_PgPbyxf2FTVhElT65CU0Xfuy">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Payment Information</small>
                        <br/>
                        <small>One last thing, we need a card on file for the organization. <i>We will not charge you anything until you subscribe to a product.</i></small>
                      </div>
                      <Elements>
                        <PaymentForm
                          organization={"Maison de Code"}
                          onStripeResponse={(stripe) => {this._handleStripe(stripe)}}
                        />
                      </Elements>
                    </CardBody>
                  </StripeProvider>
                }

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Register;
