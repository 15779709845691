import React from "react";
// AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import * as mutations from "../../../../graphql/mutations";
// mobx
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import TagsInput from "react-tagsinput";
import { Editor, EditorState, convertToRaw, convertFromRaw } from "draft-js";
import AlernativeHeader from "../../../../components/Headers/AlternativeHeader";

const Role = ({ user, match, history }) => {
  const [role, setRole] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const [name, setName] = React.useState(false);
  const [tags, setTags] = React.useState([
    "Software",
    "App Development",
    "DevOps",
    "Server",
    "App Security",
  ]);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  React.useEffect(() => {
    let { id } = user.user.organization;
    getRole(id);
  }, []);

  const getRole = async (organization) => {
    let { id } = match.params;
    let _role;
    try {
      _role = await API.graphql(graphqlOperation(queries.getRole, { id: id }));
    } catch (error) {
      _role = error;
    } finally {
      let roleInfo;
      if (_role.data.getRole) {
        roleInfo = _role.data.getRole;
      } else {
        console.log(_role);
        return history.push("/404");
      }
      if (roleInfo.organization.id === organization) {
        setRole(roleInfo);
        console.log(roleInfo);
      } else {
        console.log(
          `Org mismatch: Team Member: ${roleInfo.organization.id} | User: ${organization}`
        );
      }
      if (roleInfo.description) {
        let decrciptionContent = convertFromRaw(
          JSON.parse(roleInfo.description)
        );
        setEditorState(EditorState.createWithContent(decrciptionContent));
      }
    }
  };

  const updateRole = async () => {
    setEdit(false);
    const description = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    let plainText = editorState.getCurrentContent().getPlainText();
    let roleReponse;
    let input = {
      id: role.id,
      plainTextDescription: plainText,
    };
    if (description) input.description = description;
    if (name) input.name = name;
    try {
      roleReponse = await API.graphql(
        graphqlOperation(mutations.updateRole, { input })
      );
    } catch (error) {
      roleReponse = error;
    } finally {
      if (roleReponse.data && roleReponse.data.updateRole) {
        setRole(roleReponse.data.updateRole);
      } else {
        console.log(roleReponse);
      }
    }
  };

  if (!role) {
    return null;
  }

  return (
    <>
      <AlernativeHeader
        item={role.name}
        name={"Roles"}
        product={"Workforce"}
        editButtons
        editButtonText={"Edit Role"}
        onEditPressed={() => {
          focusEditor();
          setEdit(true);
        }}
        onCancelEditPressed={() => setEdit(false)}
        onSaveEditPressed={() => updateRole()}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-2" xl="4">
            <Card className="card-pricing border-0 text-center mb-4">
              <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                  Orginzational Overview
                </h4>
              </CardHeader>
              <CardBody className="px-lg-7">
                <div className="display-2">{role.users.length}</div>
                <span className="text-muted">Team Members With This Role</span>
                <ul className="list-unstyled my-4">
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-hand-holding-usd"></i>
                        </div>
                      </div>
                      <div>
                        <span className="pl-2 text-sm">$110,000 per year</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i className="fas fa-pen-fancy" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2 text-sm">5-7 years in role</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i className="fas fa-hdd" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2 text-sm">
                          $130,000 Salary Cap
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </CardBody>
              <CardFooter>
                <Button className="mb-3" color="primary" type="button">
                  Create Job Listing
                </Button>
              </CardFooter>
            </Card>
            <h3 className="mb-3">Current Job Listings</h3>

            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h5 className="h3 mb-0">React Senior Engineer</h5>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      className="btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See Listing
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row className="align-items-center">
                  <div className="col">
                    <h4 className="mb-0">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        New York City
                      </a>
                    </h4>
                    <p className="text-sm text-muted mb-0">Full Time</p>
                    <span className="text-success mr-1">●</span>
                    <small>Active</small>
                  </div>
                  <Col className="col-auto">
                    <div className="display-3">$120,000</div>
                    <span className="text-muted text-right">per year</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h5 className="h3 mb-0">Node Backend Senior Engineer</h5>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      className="btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See Listing
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row className="align-items-center">
                  <div className="col">
                    <h4 className="mb-0">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        North Texas
                      </a>
                    </h4>
                    <p className="text-sm text-muted mb-0">Full Time</p>
                    <span className="text-success mr-1">●</span>
                    <small>Active</small>
                  </div>
                  <Col className="col-auto">
                    <div className="display-3">$95,000</div>
                    <span className="text-muted text-right">per year</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Role Information</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  {edit && (
                    <div className="mb-2">
                      <h6 className="heading-small text-muted mb-2">Name</h6>
                      <Input
                        className="form-control-alternative"
                        placeholder={role.name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                      />
                    </div>
                  )}

                  <h6 className="heading-small text-muted mb-2">Description</h6>

                  <Card
                    className={`form-control-flush ${
                      edit && "form-control-alternative p-4"
                    }`}
                    onClick={focusEditor}
                  >
                    <Editor
                      ref={editor}
                      readOnly={!edit}
                      placeholder={
                        !edit &&
                        "Press the edit button to add a role description"
                      }
                      editorState={editorState}
                      onChange={(editorState) => setEditorState(editorState)}
                    />
                  </Card>

                  <h6 className="heading-small text-muted mb-2">
                    Requirements
                  </h6>
                  <ul>
                    {!edit &&
                      role.requirements &&
                      role.requirements.map((requirement) => {
                        return <li>requirement</li>;
                      })}
                  </ul>
                  <h6 className="heading-small text-muted mb-2">Tags</h6>

                  <TagsInput
                    className="bootstrap-tagsinput"
                    onChange={(e) => setTags(e)}
                    disabled={!edit}
                    tagProps={{ className: "tag badge mr-1 bg-primary" }}
                    value={tags}
                    inputProps={{
                      className: "",
                      placeholder: "",
                      disabled: !edit,
                    }}
                  />
                </Form>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Row>
                  <Col xs="8">
                    <h3 className="mb-0">Team Members With This Role</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Manager
                      </th>
                      <th className="sort" data-sort="completion" scope="col">
                        Office Location
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {role.users.map((teamMember) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              {teamMember.profileImage ? (
                                <a
                                  className="avatar rounded-circle mr-3"
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt="..."
                                    src={teamMember.profileImage}
                                  />
                                </a>
                              ) : (
                                <a
                                  className="avatar rounded-circle mr-3 bg-primary text-white ustify-content-center align-items-center"
                                  href={`/workforce/team-member/${teamMember.id}`}
                                >
                                  <b style={{ lineHeight: 0 }}>
                                    {teamMember.firstName[0].toUpperCase()}
                                    {teamMember.lastName[0].toUpperCase()}
                                  </b>
                                </a>
                              )}
                              <Media>
                                <span className="name mb-0 text-sm">
                                  <a
                                    href={`/workforce/team-member/${teamMember.id}`}
                                  >
                                    {teamMember.firstName} {teamMember.lastName}
                                  </a>
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            {teamMember.manager ? (
                              <a
                                href={`/workforce/team-member/${teamMember.manager.id}`}
                              >
                                {teamMember.manager.firstName}{" "}
                                {teamMember.manager.lastName}
                              </a>
                            ) : (
                              <span className="name mb-0 text-sm">
                                No Manager Assigned
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a
                                href={`/team/locations/${teamMember.location.id}`}
                              >
                                {teamMember.location.name}
                              </a>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Button
              color="danger"
              size="lg"
              style={{ width: "100%" }}
              type="button"
            >
              Delete Role
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default inject("user")(observer(Role));
