import Benefits from "./views/Benefits";
import Complaint from "./views/Complaint";
import Complaints from "./views/Complaints.jsx";
import Dashboard from "./views/Dashboard.jsx";
import Documents from "./views/Documents";
import Onboard from "./views/Onboard.jsx";
import Role from "./views/Role.jsx";
import Roles from "./views/Roles.jsx";
import SingleOnboard from "./views/SingleOnboard.jsx";
import Talent from "./views/Talent";
import TeamMember from "./views/TeamMember";
import TeamMembers from "./views/TeamMembers";

const routes = [
  {
    name: "Overview",
    icon: "fad fa-columns text-red",
    state: "dashboardsCollapse",
    path: "/",
    component: Dashboard,
    keepOrder: true,
    layout: "/workforce",
  },
  {
    icon: "fad fa-handshake-alt text-red",
    state: "dashboardsCollapse",
    path: "/onboard",
    name: "Onboarding",
    component: Onboard,
    layout: "/workforce",
  },
  {
    icon: "fad fa-heart text-red",
    state: "dashboardsCollapse",
    path: "/talent",
    name: "Talent",
    component: Talent,
    layout: "/workforce",
  },
  {
    icon: "fad fa-cubes text-red",
    state: "dashboardsCollapse",
    path: "/roles",
    name: "Roles",
    component: Roles,
    layout: "/workforce",
  },
  {
    icon: "fad fa-books text-red",
    state: "dashboardsCollapse",
    path: "/documents",
    name: "Documents",
    component: Documents,
    layout: "/workforce",
  },
  {
    icon: "fad fa-folder-open text-red",
    state: "dashboardsCollapse",
    path: "/complaints",
    name: "Complaints",
    component: Complaints,
    layout: "/workforce",
  },
  {
    icon: "fad fa-users text-red",
    state: "dashboardsCollapse",
    path: "/team-members",
    name: "Team Members",
    component: TeamMembers,
    layout: "/workforce",
  },
  {
    icon: "fad fa-house-leave text-red",
    state: "dashboardsCollapse",
    path: "/leave-of-absence",
    name: "Leave of Absence",
    component: TeamMembers,
    layout: "/workforce",
  },
  {
    icon: "fad fa-hands-heart text-red",
    state: "dashboardsCollapse",
    path: "/benefits",
    name: "Benefits",
    component: Benefits,
    layout: "/workforce",
  },
  {
    collapse: true,
    icon: "fad fa-hard-hat text-red",
    state: "dashboardsCollapse",
    path: "/safety",
    name: "Safety",
    component: TeamMembers,
    layout: "/workforce",
    views: [
      {
        path: "/accidents",
        name: "Accidents",
        component: TeamMembers,
        layout: "/workforce",
      },
    ],
  },
];

export const nonMenuRoutes = [
  {
    path: "/role/:id",
    name: "role",
    component: Role,
    layout: "/workforce",
  },
  {
    path: "/complaint/:id",
    name: "complaint",
    component: Complaint,
    layout: "/workforce",
  },
  {
    path: "/team-member/:id",
    name: "complaint",
    component: TeamMember,
    layout: "/workforce",
  },
  {
    path: "/onboard/:id",
    name: "Single Onboard",
    component: SingleOnboard,
    layout: "/workforce",
  },
];
export default routes;
