import { observable, decorate } from "mobx";
import { create, persist } from "mobx-persist";

export class Site {
  language = {
    name: "English",
    code: "en",
  };

  sideNavOpen = true;

  setLanguage(language) {
    return (this.language = language);
  }

  currentLanguage() {
    return this.language;
  }
}

decorate(Site, {
  language: [persist("object"), observable],
  sideNavOpen: [persist("boolean"), observable],
});

const SiteStore = new Site();
export default SiteStore;

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

hydrate("user", SiteStore)
  // post hydration
  .then(() => console.log("site hydrated"));
