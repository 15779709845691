/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import { I18n } from "aws-amplify";
import { strings } from "i18n/workforce/index";
I18n.putVocabularies(strings);

class AlternativeHeader extends React.Component {
  state = {
    edit: false,
  };
  render() {
    let { edit } = this.state;
    let props = this.props;
    let {
      editButtons,
      onEditPressed,
      onCancelEditPressed,
      onSaveEditPressed,
      editButtonText,
      onNewPressed,
      onSettingsPressed,
    } = this.props;
    return (
      <>
        <div className="header pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 d-inline-block mb-0">
                    {props.item ? props.item : props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {props.product}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {props.lead ? props.lead : props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                {editButtons ? (
                  <>
                    {edit ? (
                      <>
                        <Col className="text-right" lg="6" xs="5">
                          <Button
                            className="btn-danger"
                            color=""
                            onClick={() => {
                              this.setState({ edit: false });
                              onCancelEditPressed(true);
                            }}
                            size="sm"
                          >
                            {I18n.get("Cancel")}
                          </Button>
                          <Button
                            className="btn-neutral"
                            color=""
                            onClick={() => {
                              this.setState({ edit: false });
                              onSaveEditPressed(true);
                            }}
                            size="sm"
                          >
                            {I18n.get("Save")}
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <Col className="text-right" lg="6" xs="5">
                        <Button
                          className="btn-neutral"
                          color=""
                          onClick={(e) => {
                            this.setState({ edit: true });
                            onEditPressed(true);
                          }}
                          size="sm"
                        >
                          {editButtonText ? editButtonText : I18n.get("Edit")}
                        </Button>
                      </Col>
                    )}
                  </>
                ) : (
                  <Col className="text-right" lg="6" xs="5">
                    <Button
                      data-testid="new-button"
                      className="btn-neutral"
                      color=""
                      onClick={(e) => {
                        e.preventDefault();
                        onNewPressed(true);
                      }}
                      size="sm"
                    >
                      {I18n.get("New")}
                    </Button>
                    <Button
                      className="btn-neutral"
                      color=""
                      onClick={(e) => {
                        e.preventDefault();
                        onSettingsPressed(true);
                      }}
                      size="sm"
                    >
                      {I18n.get("Settings")}
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default AlternativeHeader;
