import Dashboard from "views/pages/workforce/views/Dashboard.jsx";
const routes = [
  {
    name: "Overview",
    icon: "fad fa-columns text-touchbase",
    state: "dashboardsCollapse",
    path: "/",
    component: Dashboard,
    keepOrder: true,
    layout: "/touchbase",
  },
  {
    name: "Clients",
    icon: "fad fa-address-book text-touchbase",
    state: "dashboardsCollapse",
    path: "/inventory",
    component: Dashboard,
    layout: "/touchbase",
  },
  {
    name: "Campaigns",
    icon: "fad fa-bullhorn text-touchbase",
    state: "dashboardsCollapse",
    path: "/orders",
    component: Dashboard,
    layout: "/touchbase",
  },
  {
    name: "Surveys",
    icon: "fad fa-poll-h text-touchbase",
    state: "dashboardsCollapse",
    path: "/orders",
    component: Dashboard,
    layout: "/touchbase",
  },
  {
    name: "Analytics",
    icon: "fad fa-analytics text-touchbase",
    state: "dashboardsCollapse",
    path: "/orders",
    component: Dashboard,
    layout: "/touchbase",
  },
  {
    name: "Web Forms",
    icon: "fad fa-browser text-touchbase",
    state: "dashboardsCollapse",
    path: "/orders",
    component: Dashboard,
    layout: "/touchbase",
  },
  {
    name: "Lead Tracking",
    icon: "fad fa-user-plus text-touchbase",
    state: "dashboardsCollapse",
    path: "/orders",
    component: Dashboard,
    layout: "/touchbase",
  },
];

export const nonMenuRoutes = [
  {
    path: "/item/:id",
    name: "Item",
    component: Dashboard,
    layout: "/stockpile",
  },
  {
    path: "/order/:id",
    name: "Order",
    component: Dashboard,
    layout: "/stockpile",
  },
];

export default routes;
