const en  = require("./en.json");
const fr  = require("./fr.json");
const pt  = require("./pt.json");
const it  = require("./it.json");
const es  = require("./es.json");
const zh  = require("./zh.json");
const hi  = require("./hi.json");
const bn  = require("./bn.json");
const ru  = require("./ru.json");
const ja  = require("./ja.json");
const de  = require("./de.json");
const ko  = require("./ko.json");
const tr  = require("./tr.json");
const vi  = require("./vi.json");

 export const strings = { 
	en:en,
	fr:fr,
	pt:pt,
	it:it,
	es:es,
	zh:zh,
	hi:hi,
	bn:bn,
	ru:ru,
	ja:ja,
	de:de,
	ko:ko,
	tr:tr,
	vi:vi,
};