import React from 'react';
// reactstrap components
import {
  Button,
  Form,
} from 'reactstrap'
//Stripe
import {CardElement, injectStripe} from 'react-stripe-elements';

class PaymentForm extends React.Component {
  state = {
    disabled:false
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    let { organization } = this.props
    this.setState({disabled:true})
    this.props.stripe.createSource({
        type: 'card',
        owner: {
          name: organization,
        },
      })
      .then(({source}) => {
        console.log("Payment Method ", source)
        this.createStripeCustomer(source, organization)
      })
      .catch(error => {
        console.log(error)
      })
  };

  async createStripeCustomer(token, name) {
    let url = "https://6xk5ftsc4f.execute-api.us-east-1.amazonaws.com/dev/charges"

    let data = {
      token,
      name,
    }

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    let stripe
    try {
      this.setState({disabled:false})
      stripe = await response.json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      stripe = e
    } finally {
      console.log(stripe)
      this.props.onStripeResponse(stripe)
    }
  }

  render() {
    let { disabled } = this.state
    return (
      <Form role="form" onSubmit={this.handleSubmit}>
          <CardElement style={{base: {fontSize: '18px'}}} />
        <div className="text-center">
          <Button className="mt-4" color="info" disabled={disabled} type="submit" >
            Add Card and Finish Sign Up
          </Button>
        </div>
      </Form>
    );
  }
}

export default injectStripe(PaymentForm);
