/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Routing
import { Link, Redirect } from "react-router-dom";
// AWS Authentication and API
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "graphql/queries.js";
// mobx
import { inject, observer } from "mobx-react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";
// document head manager
import { Helmet } from "react-helmet";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";

class CompleteSignUp extends React.Component {
  state = {
    view: "information",
    firstName: "",
    email: "",
    username: "",
    password: "",
    authCode: "",
    alert: "",
    redirect: false,
    user: null,
  };

  async componentDidMount() {
    let {
      params: { id },
    } = this.props.match;
    let _user;
    try {
      _user = await API.graphql(graphqlOperation(queries.getUser, { id: id }));
    } catch (error) {
      _user = error;
    } finally {
      if (_user.data && _user.data.getUser) {
        this.setState({ user: _user.data.getUser });
      }
    }
  }

  //TODO: Add User to userStore
  async _createAccount(e) {
    let {
      username,
      password,
      user: { id },
    } = this.state;
    e.preventDefault();
    this.setState({ alert: null });
    Auth.signUp({
      username,
      password,
      attributes: {
        email: this.state.user.email,
        "custom:id": id,
      },
    })
      .then((user) => {
        this.setState({ view: "verificationCode" });
      })
      .catch((e) => {
        switch (e.code) {
          case "UsernameExistsException":
            this.setState({
              alert: {
                variant: "danger",
                message: "This username or email already exists.",
              },
              username: "",
              focusedUsername: true,
            });
            break;
          case "InvalidParameterException":
            this.setState({
              alert: {
                variant: "danger",
                message:
                  "Your password is not strong enough. Please make sure all the requirements are blue",
              },
              password: "",
              focusPassword: true,
            });
            console.log(e);
            break;
          case "InvalidPasswordException":
            this.setState({
              alert: {
                variant: "danger",
                message:
                  "Your password is not strong enough. Please make sure all the requirements are blue",
              },
              password: "",
              focusPassword: true,
            });
            break;
          default:
            this.setState({
              alert: {
                variant: "danger",
                message: "An unknown error occured. Please try again",
              },
            });
            break;
        }
      });
  }

  _verificationCode(e) {
    let { username, authCode, password } = this.state;
    e.preventDefault();
    this.setState({ alert: null });
    Auth.confirmSignUp(username, authCode)
      .then(() => {
        Auth.signIn(username, password).then((data) => {
          this.props.user.user = this.state.user;
          this.setState({ redirect: true });
        });
      })
      .catch((e) => {
        console.log(e);
        switch (e.code) {
          case "CodeMismatchException":
            this.setState({
              alert: {
                variant: "danger",
                message: "Incorrect verification code. Please try again",
              },
            });
            break;
          default:
            this.setState({
              alert: {
                variant: "danger",
                message: "Something went wrong. Please try again",
              },
            });
            break;
        }
      });
  }

  _resendVerificationCode(e) {
    let { username } = this.state;
    e.preventDefault();
    this.setState({ alert: null });
    Auth.resendSignUp(username)
      .then(() => {
        this.setState({
          alert: {
            variant: "primary",
            message: "Verification code was resent successfully",
          },
        });
      })
      .catch((e) => {
        this.setState({
          alert: {
            variant: "danger",
            message:
              "There was an error sending the verification code. Please try again",
          },
        });
      });
  }

  render() {
    let {
      view,
      firstName,
      email,
      username,
      password,
      authCode,
      alert,
      redirect,
      user,
    } = this.state;
    if (!user) {
      return null;
    }
    if (redirect) return <Redirect to="/team" />;
    return (
      <>
        <Helmet>
          <title>asOne | Complete Sign Up</title>
        </Helmet>
        <AuthHeader
          title={`Welcome ${user.firstName} ${user.lastName}`}
          lead={`Finishing signing up and get to work with your team at ${user.organization.name}`}
        />
        {alert && (
          <UncontrolledAlert
            color={alert.variant}
            style={{
              zIndex: 999,
              position: "fixed",
              top: 50,
              left: 50,
              right: 50,
            }}
          >
            <span className="alert-icon">
              {alert.variant === "danger" ? (
                <i class="fad fa-frown-open"></i>
              ) : (
                <i class="fad fa-thumbs-up"></i>
              )}
            </span>
            <span className="alert-text ml-1">{alert.message}</span>
          </UncontrolledAlert>
        )}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                {view === "information" && (
                  <>
                    <CardHeader className="bg-transparent pb-5">
                      <div className="text-muted text-center mt-2 mb-4">
                        <small>Sign up with</small>
                      </div>
                      <div className="text-center">
                        <Button
                          className="btn-neutral btn-icon mr-4"
                          color="black"
                          href="#pablo"
                          style={{ color: "#000" }}
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i class="fab fa-apple"></i>
                          </span>
                          <span className="btn-inner--text">
                            Sign In With Apple
                          </span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon"
                          color="default"
                          onPress={() =>
                            Auth.federatedSignIn({
                              provider: "SignInWithApple",
                            })
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign up with credentials</small>
                      </div>
                      <Form
                        role="form"
                        onSubmit={this._createAccount.bind(this)}
                      >
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedUsername,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-at"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Username"
                              value={username}
                              onChange={(e) => {
                                this.setState({ username: e.target.value });
                              }}
                              type="text"
                              onFocus={() =>
                                this.setState({ focusedUsername: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedUsername: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedPassword,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-unlock-alt"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Password"
                              type="password"
                              value={password}
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                              onFocus={() =>
                                this.setState({ focusedPassword: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedPassword: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-muted font-italic">
                          <small>
                            password strength:{" "}
                            <span className="text-success font-weight-700">
                              strong
                            </span>
                          </small>
                        </div>
                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                required
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                                <span className="text-muted">
                                  I agree with the{" "}
                                  <a
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-center">
                          <Button className="mt-4" color="info" type="submit">
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </>
                )}

                {view === "verificationCode" && (
                  <>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Verification Code</small>
                        <br />
                        <small>
                          Thanks {firstName}! We just need to verify your email.
                          We sent a verification code to {email}
                        </small>
                      </div>
                      <Form
                        role="form"
                        onSubmit={this._verificationCode.bind(this)}
                      >
                        <FormGroup
                          className={classnames({
                            focused: this.state.focusedCode,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fad fa-shield-alt"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Verification Code"
                              type="code"
                              value={authCode}
                              onChange={(e) => {
                                this.setState({ authCode: e.target.value });
                              }}
                              onFocus={() =>
                                this.setState({ focusedCode: true })
                              }
                              onBlur={() =>
                                this.setState({ focusedCode: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>

                        <Link
                          className="text-muted"
                          onClick={(e) => {
                            this._resendVerificationCode(e);
                          }}
                        >
                          <small>Resend Verification Code</small>
                        </Link>

                        <div className="text-center">
                          <Button className="mt-4" color="info" type="submit">
                            Verify Account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default inject("user")(observer(CompleteSignUp));
