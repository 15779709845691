/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fetchNotification = /* GraphQL */ `
  query FetchNotification($id: ID!) {
    fetchNotification(id: $id) {
      id
      heading
      text
      time_stamp
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      firstName
      lastName
      profileImg
      email
      phone
      description
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      location {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      desk_location
      status
      messages {
        id
        users
        group_name
        messages {
          id
          message_group_id
          sender {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          is_sent
          created_at
          text
          geo_location
          media
        }
      }
      policy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isMasterAccount
        isPlatformAdmin
        hasBookkeeper
        hasCabinet
        hasConverse
        hasProjects
        hasStockpile
        hasTechframe
        hasTimetable
        hasTouchbase
        hasWorkforce
        isBookkeeperAdmin
        isCabinetAdmin
        isConverseAdmin
        isGreetAdmin
        isProjectsAdmin
        isStockpileAdmin
        isTimetableAdmin
        isTouchbaseAdmin
        isWorkforceAdmin
      }
      isTalent
      pay
      employmentStatus
      homeAddress {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      startDate
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($first: Int, $after: String, $filter: TableUserFilterInput) {
    listUsers(first: $first, after: $after, filter: $filter) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const listUsersByOrganization = /* GraphQL */ `
  query ListUsersByOrganization(
    $organization: String
    $first: Int
    $after: String
  ) {
    listUsersByOrganization(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const listUsersByOrganizationFirstName = /* GraphQL */ `
  query ListUsersByOrganizationFirstName(
    $organization: String!
    $ascending: Boolean
    $first: Int
    $after: String
    $filter: TableUserFilterInput
  ) {
    listUsersByOrganizationFirstName(
      organization: $organization
      ascending: $ascending
      first: $first
      after: $after
      filter: $filter
    ) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      stripe
      suspended
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      locations {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      clients {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      teams {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: TableOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      description
      team_members {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams($first: Int, $after: String) {
    listTeams(first: $first, after: $after) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      nextToken
    }
  }
`;
export const getMessageGroup = /* GraphQL */ `
  query GetMessageGroup($id: ID!) {
    getMessageGroup(id: $id) {
      id
      users
      group_name
      messages {
        id
        message_group_id
        sender {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        is_sent
        created_at
        text
        geo_location
        media
      }
    }
  }
`;
export const listMessageGroups = /* GraphQL */ `
  query ListMessageGroups($first: Int, $after: String) {
    listMessageGroups(first: $first, after: $after) {
      items {
        id
        users
        group_name
        messages {
          id
          message_group_id
          sender {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          is_sent
          created_at
          text
          geo_location
          media
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message_group_id
      sender {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      is_sent
      created_at
      text
      geo_location
      media
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages($first: Int, $after: String) {
    listMessages(first: $first, after: $after) {
      items {
        id
        message_group_id
        sender {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        is_sent
        created_at
        text
        geo_location
        media
      }
      nextToken
    }
  }
`;
export const getUserConnection = /* GraphQL */ `
  query GetUserConnection($nextToken: String!) {
    getUserConnection(nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      nextToken
    }
  }
`;
export const listUserConnections = /* GraphQL */ `
  query ListUserConnections($first: Int, $after: String) {
    listUserConnections(first: $first, after: $after) {
      items {
        items {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        nextToken
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      projectName
      description
      dueDate
      startDate
      geoLat
      geoLong
      isFinished
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      purchaseOrder
      sampler
      addressLineOne
      addressLineTwo
      city
      state
      zip
      receipt {
        id
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        amount
        date
        picture
      }
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: TableProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      name
      lineOne
      lineTwo
      city
      country
      state
      zip
      manager {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      geoLat
      geoLong
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: TableLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      nextToken
    }
  }
`;
export const listLocationsByOrganization = /* GraphQL */ `
  query ListLocationsByOrganization(
    $organization: String!
    $filter: TableLocationFilterInput
  ) {
    listLocationsByOrganization(organization: $organization, filter: $filter) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        lineOne
        lineTwo
        city
        country
        state
        zip
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        geoLat
        geoLong
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      team {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        name
        description
        team_members {
          id
          team {
            id
            name
            description
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      role
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: TableTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isOrganization
      clientName
      customerFirstName
      customerLastName
      addressLineOne
      addressLineTwo
      city
      state
      zip
      billingAddressLineOne
      billingAddressLineTwo
      billingCity
      billingState
      billingZip
      projects {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: TableClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      creator {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      proj {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      client {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isOrganization
        clientName
        customerFirstName
        customerLastName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        billingAddressLineOne
        billingAddressLineTwo
        billingCity
        billingState
        billingZip
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
      }
      title
      geoLat
      geoLong
      notes
      timeStamp
      media {
        id
        note {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
        mediaURL
      }
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: TableNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
      nextToken
    }
  }
`;
export const getSubdomain = /* GraphQL */ `
  query GetSubdomain($subdomain: String!) {
    getSubdomain(subdomain: $subdomain) {
      subdomain
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const listSubdomains = /* GraphQL */ `
  query ListSubdomains(
    $filter: TableSubdomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubdomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        subdomain
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      nextToken
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      isUserFile
      files {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
        file
        type
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: TableFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      folder {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        isUserFile
        files {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          folder {
            id
            isUserFile
          }
          file
          type
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
      }
      file
      type
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: TableFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        folder {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          isUserFile
          files {
            id
            file
            type
          }
          folder {
            id
            isUserFile
          }
        }
        file
        type
      }
      nextToken
    }
  }
`;
export const getPolicy = /* GraphQL */ `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      isMasterAccount
      isPlatformAdmin
      hasBookkeeper
      hasCabinet
      hasConverse
      hasProjects
      hasStockpile
      hasTechframe
      hasTimetable
      hasTouchbase
      hasWorkforce
      isBookkeeperAdmin
      isCabinetAdmin
      isConverseAdmin
      isGreetAdmin
      isProjectsAdmin
      isStockpileAdmin
      isTimetableAdmin
      isTouchbaseAdmin
      isWorkforceAdmin
    }
  }
`;
export const listPolicies = /* GraphQL */ `
  query ListPolicies(
    $filter: TablePolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        isMasterAccount
        isPlatformAdmin
        hasBookkeeper
        hasCabinet
        hasConverse
        hasProjects
        hasStockpile
        hasTechframe
        hasTimetable
        hasTouchbase
        hasWorkforce
        isBookkeeperAdmin
        isCabinetAdmin
        isConverseAdmin
        isGreetAdmin
        isProjectsAdmin
        isStockpileAdmin
        isTimetableAdmin
        isTouchbaseAdmin
        isWorkforceAdmin
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      description
      plainTextDescription
      requirements
      tags
      users {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: TableRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      nextToken
    }
  }
`;
export const listRolesByOrganization = /* GraphQL */ `
  query ListRolesByOrganization(
    $organization: String!
    $filter: TableRoleFilterInput
    $nameFilter: String
  ) {
    listRolesByOrganization(
      organization: $organization
      filter: $filter
      nameFilter: $nameFilter
    ) {
      items {
        id
        name
        description
        plainTextDescription
        requirements
        tags
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      nextToken
    }
  }
`;
export const getTalent = /* GraphQL */ `
  query GetTalent($id: ID!) {
    getTalent(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      application {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
      }
      resume
      notes {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        creator {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        proj {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        title
        geoLat
        geoLong
        notes
        timeStamp
        media {
          id
          note {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
          mediaURL
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
    }
  }
`;
export const listTalents = /* GraphQL */ `
  query ListTalents(
    $filter: TableTalentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        application {
          id
          organization {
            id
            name
            stripe
            suspended
          }
        }
        resume
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
      }
      nextToken
    }
  }
`;
export const listTalentsByOrganization = /* GraphQL */ `
  query ListTalentsByOrganization(
    $filter: TableTalentFilterInput
    $organization: String!
  ) {
    listTalentsByOrganization(filter: $filter, organization: $organization) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        application {
          id
          organization {
            id
            name
            stripe
            suspended
          }
        }
        resume
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      text
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      assignedBy {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      onboard {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      project {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        projectName
        description
        dueDate
        startDate
        geoLat
        geoLong
        isFinished
        client {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        purchaseOrder
        sampler
        addressLineOne
        addressLineTwo
        city
        state
        zip
        receipt {
          id
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          amount
          date
          picture
        }
        team {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        notes {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          creator {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          proj {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          title
          geoLat
          geoLong
          notes
          timeStamp
          media {
            id
            mediaURL
          }
        }
      }
      dueDate
      status
      systemTask
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: TableTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      organization {
        id
        name
        stripe
        suspended
        users {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        locations {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        projects {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        clients {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isOrganization
          clientName
          customerFirstName
          customerLastName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          billingAddressLineOne
          billingAddressLineTwo
          billingCity
          billingState
          billingZip
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
        teams {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          description
          team_members {
            id
            role
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
        }
      }
      user {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        firstName
        lastName
        profileImg
        email
        phone
        description
        manager {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        role {
          id
          name
          description
          plainTextDescription
          requirements
          tags
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          organization {
            id
            name
            stripe
            suspended
          }
        }
        location {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        desk_location
        status
        messages {
          id
          users
          group_name
          messages {
            id
            message_group_id
            is_sent
            created_at
            text
            geo_location
            media
          }
        }
        policy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          isMasterAccount
          isPlatformAdmin
          hasBookkeeper
          hasCabinet
          hasConverse
          hasProjects
          hasStockpile
          hasTechframe
          hasTimetable
          hasTouchbase
          hasWorkforce
          isBookkeeperAdmin
          isCabinetAdmin
          isConverseAdmin
          isGreetAdmin
          isProjectsAdmin
          isStockpileAdmin
          isTimetableAdmin
          isTouchbaseAdmin
          isWorkforceAdmin
        }
        isTalent
        pay
        employmentStatus
        homeAddress {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          name
          lineOne
          lineTwo
          city
          country
          state
          zip
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          geoLat
          geoLong
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        startDate
      }
      tasks {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        text
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        assignedBy {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        onboard {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          onboardEmailSent
        }
        project {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          projectName
          description
          dueDate
          startDate
          geoLat
          geoLong
          isFinished
          client {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          purchaseOrder
          sampler
          addressLineOne
          addressLineTwo
          city
          state
          zip
          receipt {
            id
            amount
            date
            picture
          }
          team {
            id
            name
            description
          }
          notes {
            id
            title
            geoLat
            geoLong
            notes
            timeStamp
          }
        }
        dueDate
        status
        systemTask
      }
      onboardEmailSent
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: TableOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      nextToken
    }
  }
`;
export const listOnboardingsByOrganization = /* GraphQL */ `
  query ListOnboardingsByOrganization(
    $organization: String!
    $filter: TableRoleFilterInput
    $nameFilter: String
  ) {
    listOnboardingsByOrganization(
      organization: $organization
      filter: $filter
      nameFilter: $nameFilter
    ) {
      items {
        id
        organization {
          id
          name
          stripe
          suspended
          users {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          locations {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          projects {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          clients {
            id
            isOrganization
            clientName
            customerFirstName
            customerLastName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            billingAddressLineOne
            billingAddressLineTwo
            billingCity
            billingState
            billingZip
          }
          teams {
            id
            name
            description
          }
        }
        user {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          firstName
          lastName
          profileImg
          email
          phone
          description
          manager {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          role {
            id
            name
            description
            plainTextDescription
            requirements
            tags
          }
          location {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          desk_location
          status
          messages {
            id
            users
            group_name
          }
          policy {
            id
            isMasterAccount
            isPlatformAdmin
            hasBookkeeper
            hasCabinet
            hasConverse
            hasProjects
            hasStockpile
            hasTechframe
            hasTimetable
            hasTouchbase
            hasWorkforce
            isBookkeeperAdmin
            isCabinetAdmin
            isConverseAdmin
            isGreetAdmin
            isProjectsAdmin
            isStockpileAdmin
            isTimetableAdmin
            isTouchbaseAdmin
            isWorkforceAdmin
          }
          isTalent
          pay
          employmentStatus
          homeAddress {
            id
            name
            lineOne
            lineTwo
            city
            country
            state
            zip
            geoLat
            geoLong
          }
          tasks {
            id
            text
            dueDate
            status
            systemTask
          }
          startDate
        }
        tasks {
          id
          organization {
            id
            name
            stripe
            suspended
          }
          text
          user {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          assignedBy {
            id
            firstName
            lastName
            profileImg
            email
            phone
            description
            desk_location
            status
            isTalent
            pay
            employmentStatus
            startDate
          }
          onboard {
            id
            onboardEmailSent
          }
          project {
            id
            projectName
            description
            dueDate
            startDate
            geoLat
            geoLong
            isFinished
            purchaseOrder
            sampler
            addressLineOne
            addressLineTwo
            city
            state
            zip
          }
          dueDate
          status
          systemTask
        }
        onboardEmailSent
      }
      nextToken
    }
  }
`;
