/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";

// AWS
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../../graphql/queries.js";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

import Icon from "../../../components/Icon/asone.jsx";

var Prismic = require("prismic-javascript");
var apiEndpoint = "https://asone.cdn.prismic.io/api/v2";

class UseCase extends React.Component {
  state = {
    products: null,
  };

  async componentDidMount() {
    let products = null;
    await Prismic.getApi(apiEndpoint)
      .then(function (api) {
        return api.query(Prismic.Predicates.at("document.type", "product"));
      })
      .then(
        function (response) {
          products = response.results;
        },
        function (err) {
          console.log("Something went wrong: ", err);
        }
      );
    this.setState({ products });
    console.log(products);
  }

  render() {
    let { products } = this.state;
    return (
      <>
        <div className="main-content">
          <AuthHeader />
          <section className="section section-lg pt-lg-0 mt--7">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row>
                    {products &&
                      products
                        .sort(function (a, b) {
                          if (a.uid < b.uid) {
                            return -1;
                          }
                          if (a.uid > b.uid) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((product) => {
                          return (
                            <Col lg="4">
                              <Link
                                to={`/products/${product.uid}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Card className="card-lift--hover shadow border-0">
                                  <CardBody className="py-5">
                                    <div
                                      className="icon icon-shape text-white rounded-circle mb-4"
                                      style={{
                                        background: `linear-gradient(87deg, ${product.data.color_secondary} 0, ${product.data.color_primary} 100%)`,
                                      }}
                                    >
                                      <i className={product.data.nucleo_icon} />
                                    </div>
                                    <h4
                                      className="h3 text-uppercase"
                                      style={{
                                        color: product.data.color_secondary,
                                      }}
                                    >
                                      {product.data.name[0].text}
                                    </h4>
                                    <p className="description mt-3">
                                      {product.data.soustext[0].text}
                                    </p>
                                    <div>
                                      {product.data.concepts.map((concept) => {
                                        return (
                                          <Badge
                                            style={{
                                              backgroundColor:
                                                product.data.color_secondary,
                                              color: "#fff",
                                            }}
                                            pill
                                          >
                                            {concept.concept}
                                          </Badge>
                                        );
                                      })}
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                          );
                        })}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default UseCase;
