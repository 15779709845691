import React from "react";
// AWS
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
// mobx
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  UncontrolledDropdown,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.jsx";
// import ReactToPrint from "react-to-print";

const Roles = ({ user }) => {
  const [roles, setRoles] = React.useState(null);
  const [deleteRole, setDeleteRole] = React.useState(false);
  const [roleToDelete, setRoleToDelete] = React.useState(null);

  React.useEffect(() => {
    let { id } = user.user.organization;
    getRoles(id);
  }, []);

  const getRoles = async (id) => {
    let _roles;
    let options = {
      organization: id,
    };
    console.log(options);
    try {
      _roles = await API.graphql(
        graphqlOperation(queries.listRolesByOrganization, options)
      );
    } catch (error) {
      _roles = error;
    } finally {
      if (_roles.data && _roles.data.listRolesByOrganization.items) {
        setRoles(_roles.data.listRolesByOrganization.items);
      } else {
        console.log("Roles ", _roles);
      }
    }
  };
  if (!roles) {
    return null;
  }
  return (
    <>
      <AlternativeHeader product={"Workforce"} name={"Roles"} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Roles</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Role
                    </th>

                    <th className="sort" data-sort="status" scope="col">
                      Team Members
                    </th>
                    <th className="sort" data-sort="completion" scope="col">
                      Description
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {roles.map((role, index) => {
                    return (
                      <tr>
                        <th scope="row">
                          <a href={`/workforce/role/${role.id}`}>{role.name}</a>
                        </th>
                        <th scope="row">
                          <div className="avatar-group">
                            {role.users.map((user, userIndex) => {
                              return (
                                <>
                                  {user.profileImage ? (
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href={`/workforce/team-member/${user.id}`}
                                      id={`usertooltip${index}${userIndex}`}
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/theme/team-1.jpg")}
                                      />
                                      )
                                    </a>
                                  ) : (
                                    <a
                                      className="avatar avatar-sm rounded-circle bg-primary text-white ustify-content-center align-items-center"
                                      href={`/workforce/team-member/${user.id}`}
                                      id={`usertooltip${index}${userIndex}`}
                                    >
                                      <b style={{ lineHeight: 0 }}>
                                        {user.firstName[0].toUpperCase()}
                                        {user.lastName[0].toUpperCase()}
                                      </b>
                                    </a>
                                  )}
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={`usertooltip${index}${userIndex}`}
                                  >
                                    {user.firstName} {user.lastName}
                                  </UncontrolledTooltip>
                                </>
                              );
                            })}
                          </div>
                        </th>

                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem href={"/workforce/role/" + role.id}>
                                See Details
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Create Job Listing
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  setDeleteRole(true);
                                  setRoleToDelete(role);
                                }}
                              >
                                Delete Role
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={deleteRole}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDeleteRole(false);
                setRoleToDelete(null);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">
                Are You Sure Want To Delete the{" "}
                {roleToDelete && roleToDelete.name} Role
              </h4>
              <p>This action cannot be undone</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button">
              Delete Role
            </Button>
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDeleteRole(false);
                setRoleToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
};
export default inject("user")(observer(Roles));
