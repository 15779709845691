import React from "react";
import {
  Badge,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.jsx";
import { I18n } from "aws-amplify";
import { strings } from "i18n/workforce/index";
const moment = require("moment");

I18n.putVocabularies(strings);

let complaints = [
  {
    user: {
      fistName: "Jordan",
      lastName: "Alexander",
      role: "Senior Sales Rep",
      location: "Shreveport",
      profilePicture: "https://randomuser.me/api/portraits/men/85.jpg",
    },
    date: "2020-05-18T19:09:33-04:00",
    title:
      "My boss is really snippy with me, and I don't know what to do about it.",
    status: "Under Review",
    complaintCategory: {
      name: "Interpersonal Challenges",
    },
  },
  {
    user: {
      fistName: "Dana",
      lastName: "Mathews",
      role: "Senior Sales Rep",
      location: "Shreveport",
      profilePicture: "https://randomuser.me/api/portraits/women/2.jpg",
    },
    date: "2020-05-13T19:09:33-04:00",
    title: "What are all these deductions from my paycheck?",
    status: "Esclated",
    complaintCategory: {
      name: "Payroll and Check",
    },
  },
  {
    user: {
      fistName: "George",
      lastName: "Romero",
      role: "Senior Sales Rep",
      location: "Shreveport",
      profilePicture: "https://randomuser.me/api/portraits/men/47.jpg",
    },
    date: "2020-05-20T19:09:33-04:00",
    title:
      "I need your help talking to my manager about my job title. It doesn't match my responsibilities",
    status: "Under Review",
    complaintCategory: {
      name: "Roles, Job Titles and Pay Levels",
    },
  },
  {
    user: {
      fistName: "Tiffany",
      lastName: "Graves",
      role: "Product Engineer",
      location: "New York City",
      profilePicture: "https://randomuser.me/api/portraits/women/23.jpg",
    },
    date: "2020-05-13T19:09:33-04:00",
    title:
      "One of the managers always tells me to put my hair up because he likes it that way. It's gross. I wanted you to know about it.",
    status: "Meeting Requested",
    complaintCategory: {
      name: "Discrimination or Harassment ",
    },
  },
  {
    user: {
      fistName: "Adam",
      lastName: "Robertson",
      role: "Product Engineer",
      location: "New York City",
      profilePicture: "https://randomuser.me/api/portraits/men/18.jpg",
    },
    date: "2020-05-13T19:09:33-04:00",
    title:
      "Should I apply for one of these internal opportunities I see posted in our job listings?",
    status: "Meeting Requested",
    complaintCategory: {
      name: "Internal Transfers and Career Advancement",
    },
  },
];

export default () => {
  return (
    <>
      <AlternativeHeader product={"Workforce"} name={I18n.get("Complaints")} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{I18n.get("Active Complaints")}</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      {I18n.get("Team Member")}
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      {I18n.get("Filing Date")}
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      {I18n.get("Category")}
                    </th>
                    <th className="sort" data-sort="completion" scope="col">
                      {I18n.get("Status")}
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {complaints.map((complaint, index) => {
                    return (
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar avatar-sm rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                src={complaint.user.profilePicture}
                              />
                            </a>
                            <Media>
                              <span className="name mb-0 text-sm">
                                {complaint.user.fistName}{" "}
                                {complaint.user.lastName}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{moment(complaint.date).format("MM/DD/YYYY")}</td>
                        <td>{complaint.complaintCategory.name}</td>

                        <td>
                          <Badge className="badge-dot mr-4" color="">
                            {complaint.status}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem href={"/app/complaint/" + index}>
                                See Details
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Delete Complaint
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
