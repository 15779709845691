/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/pages/components/Buttons.jsx";
import Calendar from "views/pages/Calendar.jsx";
import Cards from "views/pages/components/Cards.jsx";
import Charts from "views/pages/Charts.jsx";
import CompleteSignUp from "views/pages/auth/CompleteSignUp.jsx";
import Components from "views/pages/forms/Components.jsx";
import ForgotPassword from "views/pages/auth/ForgotPassword.jsx";
//import Elements from "views/pages/forms/Elements.jsx";
import Google from "views/pages/maps/Google.jsx";
import Grid from "views/pages/components/Grid.jsx";
import Icons from "views/pages/components/Icons.jsx";
import Lock from "views/pages/auth/Lock.jsx";
import Login from "views/pages/auth/Login.jsx";
import Notifications from "views/pages/components/Notifications.jsx";
import Pricing from "views/pages/examples/Pricing.jsx";
import Profile from "views/pages/examples/Profile.jsx";
import ReactBSTables from "views/pages/tables/ReactBSTables.jsx";
import Register from "views/pages/auth/Register.jsx";
import Settings from "views/pages/settings/Settings.jsx";
import Sortable from "views/pages/tables/Sortable.jsx";
import Tables from "views/pages/tables/Tables.jsx";
import Timeline from "views/pages/examples/Timeline.jsx";
import Typography from "views/pages/components/Typography.jsx";
import Validation from "views/pages/forms/Validation.jsx";
import Vector from "views/pages/maps/Vector.jsx";
import Widgets from "views/pages/Widgets.jsx";

const routes = [
  {
    collapse: true,
    name: "Bookkeeper",
    icon: "ni ni-books text-pink",
    state: "bookkeeperCollapse",
    views: [
      {
        path: "/components",
        name: "Components",
        component: Components,
        layout: "/app",
      },
      {
        path: "/validation",
        name: "Reports",
        icon: "fa fa-chart-pie text-pink",
        component: Validation,
        layout: "/app",
      },
      {
        collapse: true,
        name: "Sales",
        state: "bkSaleMultiCollapse",
        icon: "fa fa-file-invoice-dollar text-pink",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/",
          },
        ],
      },
      {
        collapse: true,
        name: "Transactions",
        state: "bkTransactionsMultiCollapse",
        icon: "fa fa-file-invoice-dollar text-pink",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/",
          },
        ],
      },
      {
        collapse: true,
        name: "Expenses",
        icon: "fa fa-money-bill-wave-alt text-pink",
        state: "bkExpensesMultiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Projects",
    icon: "ni ni-spaceship text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        component: Timeline,
        layout: "/app",
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        layout: "/app",
      },
    ],
  },
  {
    collapse: true,
    name: "Cabinet",
    icon: "ni ni-archive-2 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        component: Buttons,
        layout: "/app",
      },
      {
        path: "/cards",
        name: "Cards",
        component: Cards,
        layout: "/app",
      },
      {
        path: "/grid",
        name: "Grid",
        component: Grid,
        layout: "/app",
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        layout: "/app",
      },
      {
        path: "/icons",
        name: "Icons",
        component: Icons,
        layout: "/app",
      },
      {
        path: "/typography",
        name: "Typography",
        component: Typography,
        layout: "/app",
      },
      {
        collapse: true,
        name: "Multi Level",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Greet",
    icon: "ni ni-bell-55 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/sortable",
        name: "Sortable",
        component: Sortable,
        layout: "/app",
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        component: ReactBSTables,
        layout: "/app",
      },
    ],
  },
  {
    collapse: true,
    name: "Touchbase",
    icon: "ni ni-book-bookmark text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/google",
        name: "Google",
        component: Google,
        layout: "/app",
      },
      {
        path: "/vector",
        name: "Vector",
        component: Vector,
        layout: "/app",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Converse",
    icon: "ni ni-send text-green",
    component: Widgets,
    layout: "/app",
  },
  {
    path: "/charts",
    name: "Stockpile",
    icon: "ni ni-box-2 text-info",
    component: Charts,
    layout: "/app",
  },
  {
    path: "/timetable",
    name: "Timetable",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/app",
  },
  {
    path: "/techframe",
    name: "TechFrame",
    icon: "fad fa-chart-network text-gray",
    component: Calendar,
    layout: "/app",
  },
];

export const nonMenuRoutes = [
  {
    collapse: true,
    views: [
      {
        path: "/location/:id",
        name: "Location",
        component: Validation,
        layout: "/app",
      },
      {
        path: "/team-member/:id",
        name: "Team Memeber",
        component: Profile,
        layout: "/app",
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        layout: "/app",
      },
      {
        path: "/complete-sign-up/:id",
        name: "Complete Sign Up",
        component: CompleteSignUp,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        component: ForgotPassword,
        layout: "/auth",
      },
      {
        path: "/users",
        name: "Complete Sign Up",
        component: CompleteSignUp,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
