import Users from "./Users.jsx";
import User from "./User.jsx";
const routes = [
  {
    name: "Users",
    icon: "fad fa-users text-primary",
    state: "dashboardsCollapse",
    path: "/users",
    component: Users,
    layout: "/admin",
  },
];

export const nonMenuRoutes = [
  {
    path: "/user/:id",
    name: "Item",
    component: User,
    layout: "/admin",
  },
];

export default routes;
