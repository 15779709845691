import React from "react";
export default ({ id, firstName, lastName, size }) => {
  return (
    <a
      className={
        size === "small"
          ? "avatar rounded-circle mr-3 bg-primary justify-content-center align-items-center"
          : "avatar avatar-xl rounded-circle bg-primary justify-content-center align-items-center"
      }
      href={"/app/team-member/" + id}
    >
      {size === "small" ? (
        <h3 className="text-white" style={{ lineHeight: 0.0 }}>
          {firstName[0]}
          {lastName[0]}
        </h3>
      ) : (
        <h1 className="text-white" style={{ lineHeight: 0 }}>
          {firstName[0]}
          {lastName[0]}
        </h1>
      )}
    </a>
  );
};
