/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
// i18n
import { I18n } from "aws-amplify";
import { strings } from "i18n/index/index";
I18n.putVocabularies(strings);

class IndexHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-info pt-5 pb-7">
          <Container>
            <div className="header-body">
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pr-5">
                    <h1 className="display-2 text-white font-weight-bold mb-0">
                      {I18n.get("slogan")}
                    </h1>
                    <h2 className="display-4 text-white font-weight-light">
                      {I18n.get("subtitle")}
                    </h2>
                    <p className="text-white mt-4">{I18n.get("headerText")}</p>
                    <div className="mt-5">
                      <LinkScroll className="mr-2" to="products" smooth={true}>
                        <Button
                          className="btn-neutral"
                          color="default"
                          tag={Link}
                        >
                          {I18n.get("exportAllProducts")}
                        </Button>
                      </LinkScroll>
                      <Button
                        className="my-2"
                        color="default"
                        href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=adpr-auth-navbar"
                      >
                        {I18n.get("signUpNow")}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Row className="pt-5">
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                            <i className="ni ni-building" />
                          </div>
                          <h5 className="h3">Workforce</h5>
                          <p>
                            HR management that actually works for your company
                          </p>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                            <i className="ni ni-spaceship" />
                          </div>
                          <h5 className="h3">Projects</h5>
                          <p>
                            Project management made simple and usable to get the
                            job done faster
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="pt-lg-5 pt-4" md="6">
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                            <i className="ni ni-books" />
                          </div>
                          <h5 className="h3">Bookkeeper</h5>
                          <p>
                            Accounting made to work for any size from start-ups
                            to fortune 500 companies
                          </p>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                            <i className="ni ni-archive-2" />
                          </div>
                          <h5 className="h3">Cabinet</h5>
                          <p>
                            Files storage has never been easier to use, and
                            provides colaberation and security
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}

export default IndexHeader;
